import axios from 'axios';
import {config} from '../../constants/config';

export const getChefDetails = async(id) => {
    const res = await axios({
        method: 'GET',
        url: `${config.url.production}api/v1/users/${id}`,
        dataType: 'json',
        headers: {
            // 'Access-Control-Allow-Origin': '*', 
            'Content-Type': 'application/json'
          },
    });
    return res.data;
}