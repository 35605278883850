import axios from 'axios';
import {config} from '../../constants/config';

export const getMyShoppingList = async(userId) => {
    const res = await axios({
        method: 'GET',
        url: `${config.url.production}api/v1/users/${userId}/shoppinglist`,
        withCredentials: true,
    });
    return res.data
}

export const addToShoppingList = async(data) => {

    try {
        const res = await axios({
            method: 'POST',
            url: `${config.url.production}api/v1/shoppinglist`,
            withCredentials: true,
            dataType: 'json',
            Headers: {
                'Content-Type': 'application/json',
                // 'Access-Control-Allow-Origin': '*',
            },
            data
        });

        return res.data
    }
    catch (err) {
        console.log(err);
    }
}

export const saveShoppingList = async(id, data) => {

    try {
        const res = await axios({
            method: 'PATCH',
            url: `${config.url.production}api/v1/shoppinglist/${id}`,
            withCredentials: true,
            dataType: 'json',
            Headers: {
                'Content-Type': 'application/json',
                // 'Access-Control-Allow-Origin': '*',
            },
            data
        });

        return res.data
    }
    catch (err) {
        console.log(err);
    }
}

export const removeFromShoppingList = async(id) => {

    const res = await axios({
        method: 'DELETE',
        url: `${config.url.production}api/v1/shoppinglist/${id}`,
        withCredentials: true,
    });
    return res;
}