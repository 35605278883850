import React from "react"
import styled from 'styled-components';

import { MyColors } from "../constants/colors";

export const ButtonPrimary = styled.button`
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    padding: 0.5rem;
    margin: 0.4rem;
    border-radius: 0.2rem;
    border: 0;
    color: ${MyColors.colors.white};
`

export const ButtonSecondary = styled.button`
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    padding: 0.5rem;
    margin: 0.4rem;
    border-radius: 0.2rem;
    border: 0;
    color: ${MyColors.colors.primary};
    border: 1px solid ${MyColors.colors.primary};
    background-color: ${MyColors.colors.white};
`

const LinkButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.2rem;
    margin: 0.4rem;
    cursor: pointer;
    color: ${MyColors.colors.primary};
`

const ButtonWrapper = styled.div`
    display: flex;
    flex: 1;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
`

function MyButton(props) {
    const type = props.type;

    if(type === 'primary') {
        return (
            <ButtonWrapper onClick={props.action}>
                <ButtonPrimary style={{backgroundColor: props.bgcolor? props.bgcolor : MyColors.colors.primary}}>{props.label}</ButtonPrimary>
            </ButtonWrapper>
        )
    }

    else if(type === 'primaryIcon') {
        return (
            <ButtonWrapper onClick={props.action}>
                <ButtonPrimary style={{backgroundColor: props.bgcolor? props.bgcolor : MyColors.colors.primary}}>
                    {props.label}
                    {props.iconDetails}
                </ButtonPrimary>
            </ButtonWrapper>
        )
    }

    else if(type === 'secondary') {
        return (
            <ButtonWrapper onClick={props.action}>
                <ButtonSecondary style={{borderWidth: 1, borderColor: props.bgcolor? props.bgcolor : MyColors.colors.primary, color: props.bgcolor? props.bgcolor : MyColors.colors.primary}}>{props.label}</ButtonSecondary>
            </ButtonWrapper>
        )
    }

    else if(type === 'link') {
        return (
            <LinkButtonWrapper onClick={props.action}>
                {props.label}
            </LinkButtonWrapper>
        )
    }
}

export default MyButton