/* eslint-disable */
import React,{useContext} from "react"
import styled from 'styled-components';
import {Link} from 'react-router-dom'

import {NavLinkStyle} from '../constants/cssstyles'
import {NavItem, NavItemSelected} from '../constants/styles'
import {UserContext,} from '../store/context/user-context';

const ContentBlock = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 1rem;
    padding-right: 1rem;
`

function UserSideNav(props) {
    const { user } = useContext(UserContext);
    const selectedScreen = props.selectedScreen? props.selectedScreen : 'allmeals'

    return (
        <ContentBlock>

            <Link to='/' style={NavLinkStyle}>
                {
                    selectedScreen === 'allmeals'?
                    <NavItemSelected>All Meals</NavItemSelected>
                    :
                    <NavItem>All Meals</NavItem>
                }
                
            </Link>

            {
                Object.keys(user).length === 0?
                // <Link to='/' style={NavLinkStyle}>
                //     <NavItemSelected>All Meals</NavItemSelected>
                // </Link>
                ''
                :
                <>
                    <Link to='/mymeals' params={{ testvalue: "hello" }} style={NavLinkStyle}>
                        {
                            selectedScreen === 'mymeals'?
                            <NavItemSelected>My Meals</NavItemSelected>
                            :
                            <NavItem>My Meals</NavItem>
                        }
                        
                    </Link>
                </>
            }

            <Link to='/chefs' params={{ testvalue: "hello" }} style={NavLinkStyle}>
                {
                    selectedScreen === 'chefs'?
                    <NavItemSelected>Chefs</NavItemSelected>
                    :
                    <NavItem>Chefs</NavItem>
                }
                
            </Link>

        </ContentBlock>
    )
}

export default UserSideNav