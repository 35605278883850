import React, {useState, useEffect, useContext} from 'react'
import styled from 'styled-components';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { RWebShare } from "react-web-share";
import { ArrowBack, LocalDining, AccessAlarm, Microwave, Publish, Edit, Delete, Share, Print} from '@mui/icons-material';
import Calendar from 'react-calendar';

import {MyColors} from '../constants/colors';
import { iconBlue, iconWhite, iconPrimary } from '../constants/cssstyles';
import {getMeal, getMyMeal, addMeal, updateMeal, deleteMeal, getExistingMealtypesForMeal, moveMeal} from '../controllers/api/mealsController'
import { MainHeading, ContentTextMediumRegular, ErrorText, ContentTextLargeBold, ContentTextMediumBold, ContentTextSmallRegular} from '../constants/styles'
import MyInput from '../components/MyInput';
import MyTextArea from '../components/MyTextArea';
import {UserContext} from '../store/context/user-context';
import UserViewTemplate from '../components/screenTemplates/userViewTemplate';
import ReviewCard from '../components/reviewCard';
import Stars from '../components/mealStars';
import { getConvertedDate, getTodaysDate } from '../components/dateTimeComponent';
import MyList from '../components/MyList'
import {listEditHandler, listSaveHandler, moveDownInList, moveUpInList} from '../controllers/frontend/listController'
import {NutritionList} from '../constants/nutritionInfoList'
import GeneralViewTemplate from '../components/screenTemplates/generalViewTemplate';
import MyCheckboxList from '../components/myCheckboxList'
import MyModal from '../components/MyModal';
import MyButton from '../components/MyButton';
import {AddMealFromBBCGoodFood} from '../controllers/frontend/mealsController'
import { config } from '../constants/config';

const Wrapper = styled.div`
    width: 100%;
    background-color: ${MyColors.colors.white};
    border-radius: 0.4rem;
    display: flex;
    flex-direction: column;
`

const TopContent = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    border-radius: 0.4rem;
    position:relative;
`

const BottomContent = styled.div`
    display: flex;
    flex: 1;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
`

const LeftContent = styled.div`
    display: flex;
    flex: 2;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
`

const RightContent = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    // margin-left: 1rem;
    // margin-right: 1rem;
    // background-color: ${MyColors.colors.lightestgrey}
`

const MealActions = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    position: absolute;
    top: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.6);
`

const ReviewsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    // flex-wrap: wrap;
    height: 550px;
    scroll-behaviour: smooth;
    margin: 1rem;
    overflow-y: scroll;
    overflow-x: hidden;
    white-space: nowrap;
`

const ReviewHeader = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
`

const LeftBottomWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    // margin: 0.4rem;
`

const MealDetails = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0.4rem;
    flex-wrap: wrap;
`

const IngredientsContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-left: 0.4rem;
    margin-right: 0.4rem;
    padding: 0.5rem;
    border-radius: 4px;
    border: 1px solid ${MyColors.colors.lightgrey}
`

const StepsContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-left: 0.4rem;
    margin-right: 0.4rem;
    padding: 0.5rem;
    border-radius: 4px;
    border: 1px solid ${MyColors.colors.lightgrey}
`

const MealNotificationsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`

const MealNotificationBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0;
`

const ListWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`

const ChangeImg = styled.div`
    display: flex;
    flex:1;
    flex-direction: column;
    position: absolute;
    bottom: 0;
    color: ${MyColors.colors.white};
    padding: 0.2rem;
    margin: 0.2rem;
`

const NutritionListStyle = styled.div`
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0.4rem 0;
`

const MealDetailsSection = styled.div`
    margin: 0.3rem 0;
`

const MySelectStyle = styled.select`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 0 0.4rem;
    padding: 1rem;
    border: 1px solid ${MyColors.colors.lightgrey};
    border-radius: 0.2rem;
    background-color: ${MyColors.colors.white};
`

const MealNav = styled.div`
    display:flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top:0.4rem;
    padding-bottom:0.8rem;
`

const MealActionIcon = styled.div`
    width: 100px;
    // flex: 1;
    margin: 0 4px;
`

function Meal() {
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();

    const { 
        user, 
        setFeedbackMessage,
        setPageName,
        selectedCalDay,
        setSelectedCalDay, 
        addMealToPlanner,
        mealReviews,
        setMealReviews,
        addMealReview,
        mySections,
        hideUnpublishedMeal,
        datesWithMeals
    } = useContext(UserContext);

    const [selectedMeal, setMeal] = useState({});
    const [editMode, setEditMode] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    //Local variables for meal
    const [mealImage, setMealImage] = useState('default.jpg');
    const [currentMealImage, setCurrentMealImage] = useState(
        {
        display: `${config.url.production}img/meals/${mealImage}`,
        save: ''
    })

    const [mealNameState, setMealNamestate] = useState('')
    const [mealDescriptionState, setMealDescriptionState] = useState('');
    const [mealPreptime, setMealPreptime] = useState('');
    const [mealYeilds, setMealYeilds] = useState('');
    const [mealDifficulty, setMealDifficulty] = useState('');

    const [mealIngredientsList, setMealIngredientsList] = useState([]);
    const [mealStepsList, setMealStepsList] = useState([]);
    const [mealNutritionList, setMealNutritionList] = useState(NutritionList);

    const [rating, setMealStars] = useState(-1);

    //Reviews
    const [reviewDescription, setMealReviewInput] = useState([]);
    const [linkHref, setLinkHref] = useState();
    //const [mealReviews, setMealReviews] = useState([])

    const [modalIsOpen, setIsOpen] = useState(false);
    const [reviewModalIsOpen, setReviewModalIsOpen] = useState(false);
    const [publishModalIsOpen, setPublishModalIsOpen] = useState(false);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [moveModalIsOpen, setMoveModalIsOpen] = useState(false);
    const [addLinkMMealodalIsOpen, setAddLinkMMealodalIsOpen] = useState(false);
    
    const [modalError, setModalError] = useState('');

    const [selectedSection, setSelectedSection] = useState('-1');

    const todaysdate = getTodaysDate();
      
    //DROPDOWN
    const [mealTypeDropdown, setMealTypeDropdown] = useState('Breakfast')
    const [mealDropdownValues, setMealDropdownValues] = useState(['Breakfast', 'Lunch', 'Dinner']);

      const getMealToShow = async() => {
        try {
            let res;
            if(location.state && location.state.screen === 'mymeals' ) {
                res = await getMyMeal(params.slug, user._id);
            }
            else {
                res = await getMeal(params.slug);
            }

            if(res && res.status === 'success') {
                document.title = res.doc.mealName;

                const tempImg = {
                    display: `${config.url.production}img/meals/${res.doc.mealImg}`,
                    save: ''
                }
                setMeal(res.doc);
                setMealImage(res.doc.mealImg);
                setCurrentMealImage(tempImg);
                setMealNamestate(res.doc.mealName);
                setMealDescriptionState(res.doc.mealDescription);
                setMealYeilds(res.doc.yeilds);
                setMealPreptime(res.doc.prepTime);
                setMealDifficulty(res.doc.difficulty);
                setMealReviews(res.doc.reviews);
        
                setMealIngredientsList(res.doc.ingredientsList);
                setMealStepsList(res.doc.stepsList);
                setMealNutritionList(res.doc.nutritionList);
            }
        }
        catch (err) {
            console.log(err);
            navigate('/error', {
                state: {
                    name: err.name,
                    message: err.message,
                    code: err.code,
                    statuscode: err.response.status
                }
            });
        }
    }

    useEffect(() => {
        setPageName('meal');
        if(params.slug){
            getMealToShow();
        }
        else {
            document.title = 'Add new meal';
            setEditMode(true);
        }
    },[params.slug]);

    useEffect(() => {
        getMealTypesToDisplay();
    },[selectedCalDay.Tdate]);

    const changePhoto = async (event) => {
        setMealImage(URL.createObjectURL(event.target.files[0]));
        
        const imgNew = {
            display: URL.createObjectURL(event.target.files[0]),
            save: event.target.files[0]
        }
        setCurrentMealImage(imgNew)
    }

    const AddIngredient = async () => {
        const tempArray = [];
        let nextNum = 0;

        mealIngredientsList.map((item, i) => {
            tempArray.push(item)
        })

        if(mealIngredientsList.length > 0) {
            if(mealIngredientsList.length === 1) {
                nextNum = 1;
            }
            else {
                 nextNum = mealIngredientsList.length;
            }
        }

        const newItem = {
                id: nextNum,
                itemName: '',
                qty: '',
                activeStatus: true,
                editStatus: true, 
                removeStatus: false,
        }

        tempArray.push(newItem)        
        setMealIngredientsList(tempArray)
    }
    
    const ChangeIngredientText = (itemIndex, text) => {
        const tempCardArray = []
        for(let i = 0; i < mealIngredientsList.length; i++) {
            tempCardArray.push(mealIngredientsList[i])
        }
        tempCardArray[itemIndex].itemName = text
        setMealIngredientsList(tempCardArray)
    }

    const ChangeStepsText = (itemIndex, text) => {
        const tempCardArray = []
        for(let i = 0; i < mealStepsList.length; i++) {
            tempCardArray.push(mealStepsList[i])
        }
        tempCardArray[itemIndex].itemName = text
        setMealStepsList(tempCardArray)
    }

    const AddStep = async () => {
        const tempArray = [];
        let nextNum = 0;

        mealStepsList.map((item, i) => {
            tempArray.push(item)
        })

        if(mealStepsList.length > 0) {
            if(mealStepsList.length === 1) {
                nextNum = 1;
            }
            else {
                 nextNum = mealStepsList.length;
            }
        }

        const newItem = {
                id: nextNum,
                itemName: '',
                activeStatus: true,
                editStatus: true, 
                removeStatus: false,
        }

        tempArray.push(newItem)        
        setMealStepsList(tempArray)
    }

    function navigateToMeal(slug) {
        //redirect if not on the page already
        if(!params.slug){
            navigate(`/${slug}`, {state:{screen: 'mymeals'}});
        }
        // else {
        // //redirect if slug different from the one in the current url
        //     if(params.slug !== slug) {
        //         navigate(`/${slug}`);
        //     }
        // }
      }

    const toggleEdit = async () => {
        if(editMode) {
            const mealId = selectedMeal.id;
            
            const formToUpdate = new FormData();
            formToUpdate.append('mealName', mealNameState);
            formToUpdate.append('mealDescription', mealDescriptionState);
            formToUpdate.append('mealImg', currentMealImage.save);
            formToUpdate.append('prepTime', mealPreptime);
            formToUpdate.append('yeilds', mealYeilds);
            formToUpdate.append('difficulty', mealDifficulty);
            formToUpdate.append('ingredientsList', mealIngredientsList);
            formToUpdate.append('stepsList', mealStepsList);
            formToUpdate.append('nutritionList', mealNutritionList);

            try{ 
                if(!params.slug){

                    const form = new FormData();
                    form.append('user', user._id);
                    form.append('mealName', mealNameState);
                    form.append('mealDescription', mealDescriptionState);
                    form.append('mealImg', currentMealImage.save);
                    form.append('prepTime', mealPreptime);
                    form.append('yeilds', mealYeilds);
                    form.append('difficulty', mealDifficulty);
                    form.append('section', params.section);

                    form.append('ingredientsList', JSON.stringify(mealIngredientsList));
                    form.append('stepsList', JSON.stringify(mealStepsList));
                    form.append('nutritionList', JSON.stringify(mealNutritionList));

                    // const newMeal = await addMeal(form)

                    if(newMeal.status === 'success') {
                        setMeal(newMeal.doc);
                        setFeedbackMessage('New meal added successfully');
                        navigateToMeal(newMeal.doc.slug);
                    }
                }
                else {
                    const newMeal = await updateMeal(mealId, formToUpdate);
                    
                    if(newMeal.status === 'success') {
                        setMeal(newMeal.doc);
                        setFeedbackMessage('Meal updated successfully');
                    }
                }
                setEditMode(false);
            }
            catch(err) {
                navigate('/error', {
                    state: {message: 'Log in and try again', code: 'NOT_AUTHORIZED'}
                });
            }
        } 
        else {
            setEditMode(true);
        }
    }

    const deleteMealHandler = async() => {
        try{
            const res = await deleteMeal(selectedMeal.id);
            if(res.data.status === 'success') {
                //Go Back to homepage
                setFeedbackMessage('Meal deleted');
                navigate(-1);
            }
        }
        catch(err) {
            console.log(err);
            //setErrorMessage(err.response.data.message);
        }
    }

    const moveMealHandler = async() => {
        setModalError('');
        if(selectedSection !== '-1') {
            try{
                const res = await moveMeal(selectedMeal.id, selectedSection);

                if(res.status === 'success') {
                    setMeal(res.doc)
                    setFeedbackMessage('Meal moved');
                    setMoveModalIsOpen(false);

                    //set dropdown back to start
                    setSelectedSection('-1')
                }
            }
            catch(err) {
                console.log(err);
                //setErrorMessage(err.response.data.message);
            }
        }
        else {
            setModalError('Please select a section');
        }
    }

    const addLinkMealMealHandler = async() => {
        setModalError('');
        closeModal('addlinkmeal');

        if(linkHref.includes('bbcgoodfood')) {
            const meal = await AddMealFromBBCGoodFood(linkHref);

            if(meal) {
                setMealImage(meal.mealImage);
                setMealNamestate(meal.mealName);
                setMealDescriptionState(meal.mealDesc);
                setMealYeilds(meal.mealYeilds);
                setMealPreptime(meal.prepTime);
                setMealDifficulty(meal.difficulty);
            }
    
            setMealIngredientsList(meal.tempIngList);
            setMealStepsList(meal.methodList2);
            console.log(mealNutritionList)
            setMealNutritionList(meal.nutritionListTemp);

            // setLoadingSpinnerState(true)
            // AddMealFromBBCGoodFood()
            // setMealFromText('Meal from www.bbcgoodfood.com')
        }
        else if (linkHref.includes('allrecipes')) {
            console.log('allrecipes');
            // setLoadingSpinnerState(true)
            // AddMealFromAllRecepies()
            // setMealFromText('Meal from www.allrecipes.com')
        }
        else if (linkHref.includes('delish')) {
            console.log('delis§');
            // setLoadingSpinnerState(true)
            // AddMealFromDelish()
            // setMealFromText('Meal from www.delish.com')
        }
        
        else {
            setLinkNotfoundModal(true)
        }
    }

    const getMealTypesToDisplay = async() => {
        //Populate the dropdown
        try {
            if(selectedMeal.user) {
                const data = {
                    user: selectedMeal.user._id,
                    mealId: selectedMeal.id,
                    scheduleDate: selectedCalDay.Tdate,
                }

                const getExistingMealtypes = await getExistingMealtypesForMeal(data);
                const temp = [];

                if(!getExistingMealtypes.mealTypes.includes('Breakfast')) {
                    temp.push('Breakfast')
                }
                if(!getExistingMealtypes.mealTypes.includes('Lunch')) {
                    temp.push('Lunch')
                }
                if(!getExistingMealtypes.mealTypes.includes('Dinner')) {
                    temp.push('Dinner')
                }
                setMealDropdownValues(temp);
            }
        }
        catch (err) {
            console.log(err);
            // setErrorMessage(err.response.data.message);
        }
    }
    
    function closeModal(modalName) {
        document.body.style.overflow = "auto";
        
        if(modalName === 'review') {
            setReviewModalIsOpen(false);
        }
        else if(modalName === 'publish') {
            setPublishModalIsOpen(false);
        }
        else if(modalName === 'deletemeal') {
            setDeleteModalIsOpen(false);
        }
        else if(modalName === 'movemeal') {
            setMoveModalIsOpen(false);
        }
        else if(modalName === 'addlinkmeal') {
            setAddLinkMMealodalIsOpen(false);
        }
        else {
            setIsOpen(false);
        }
    }

      function openModal(modalName) {
        document.body.style.overflow = "hidden";
        
        if(modalName === 'review') {
            setReviewModalIsOpen(true);
        }
        else if(modalName === 'publish') {
            setPublishModalIsOpen(true);
        }
        else if(modalName === 'deletemeal') {
            setDeleteModalIsOpen(true);
        }
        else if(modalName === 'movemeal') {
            setMoveModalIsOpen(true);
        }
        else if(modalName === 'addlinkmeal') {
            setAddLinkMMealodalIsOpen(true);
        }
        else {
            setIsOpen(true);
        }
    }

    const addMealToPlannerHandler = async() => {
        await addMealToPlanner(data);
        closeModal('calendar');
    }

    const changeDropdownHandler = (value) => {
        const temp = {
            sectionId: value.target.value,
            sectionName: value.target.value,
        }
        setMealTypeDropdown(value.target.value)
    }

    const printMeal = () => {
        print();
    }

    const shareMeal = () => {
        console.log('Share')
    }

    const onDateChange = (date) => {
        const newDate = getConvertedDate(date);
        setSelectedCalDay(newDate)
    }

    function changeStatus(type, status, index) {
        if(type === 'ingredients'){
            const ingredients = [...mealIngredientsList];
            ingredients[index].removeStatus = status;
            setMealIngredientsList(ingredients);
        }

        else if(type === 'steps') {
            const steps = [...mealStepsList];
            steps[index].removeStatus = status;
            setMealStepsList(steps);
        }
    }

    async function addReviewHandler() {
        const data = {
            reviewDescription,
            rating,
        }

        addMealReview(data, selectedMeal.id);
        closeModal('review');
    }

    async function publishMealHandler() {
        const publishStatus = selectedMeal.published? false : true;

        const data = {
            published: publishStatus, 
        }
            
        const newMeal = await updateMeal(selectedMeal.id, data);
            setMeal(newMeal.doc);
            hideUnpublishedMeal(selectedMeal.id, publishStatus);
            closeModal('publish');
        }

    const changeSelectedSection = (value) => {
        setSelectedSection(value.target.value);
        setModalError('');
    }

    function tileClassName({ date, view }) {

        const newDate = getConvertedDate(date);
        var Tdate = newDate.Tdate;

        if(view === 'month' && Tdate === selectedCalDay.Tdate){
            return  'currentActive'
        }
        else if(view === 'month' && datesWithMeals.find(dDate => dDate === Tdate)){
            return  'highlight'
        }  
      }

    const reviewModalContent = 
    <>
          <Stars 
            numOfStars={rating} 
            clickable={true}
            clickOne={()=>{
                setMealStars(1);
            }}
            clickTwo={()=>{
                setMealStars(2);
            }}
            clickThree={()=>{
                setMealStars(3);
            }}
            clickFour={()=>{
                setMealStars(4);
            }}
            clickFive={()=>{
                setMealStars(5);
            }}
        />
        <MyInput 
            label="Review Comments" 
            inputValue={reviewDescription}
            inputId='reviewcomment'
            handleChange={(val) => {setMealReviewInput(val.target.value)}}
        />
    </>

    const calendarModalContent = 
    <>
         <ContentTextMediumRegular>Adding meal for {selectedCalDay.TdateFormatted}</ContentTextMediumRegular>
        <>
            <Calendar 
                onChange={date => onDateChange(date)} 
                value={todaysdate.Tdate} 
                className="react-calendar" 
                tileClassName={tileClassName}
            />

                <option value={-1}>Please select a meal time</option>
            {
                mealDropdownValues.length > 0?
                <select value = {mealTypeDropdown} onChange={changeDropdownHandler}>
                {
                    
                    mealDropdownValues.map((item) => {
                        return (
                            <option key={item} value={item}>{item}</option>
                        )
                    })
                }
            </select>
            :
            <ContentTextMediumRegular>You have already added this meal to Breakfast, Lunch and Dinner for this date</ContentTextMediumRegular>
            }
        </>
    </>

    const publishModalContent = 
        <>
            <ContentTextMediumRegular>Are you sure you want to publish this meal?</ContentTextMediumRegular>
        </>

    const moveModalContent =
        <>
            {
                mySections.length > 0?
                <>
                    <MySelectStyle value = {selectedSection} onChange={changeSelectedSection}>
                        <option value={-1}>Please select a section</option>
                    {
                        mySections.map((item) => {
                            if( selectedMeal.section && item.sectionId !== selectedMeal.section.id){
                                return (
                                    <option key={item.sectionId} value={item.sectionId}>{item.sectionName}</option>
                                )
                            }
                        })
                    }
                    </MySelectStyle>
                </>
                :
                <ContentTextMediumRegular>No sections</ContentTextMediumRegular>
            }
        </>

    const addLinkMealModalContent =
    <>
        <MyInput 
            label="Add link" 
            inputValue={linkHref}
            inputId='link'
            handleChange={(val) => {setLinkHref(val.target.value)}}
        />
    </>

    const deleteMealModalContent =
        <>
            <ContentTextMediumRegular>Are you sure you want to delete this meal?</ContentTextMediumRegular>
        </>

    const contentToShow =  <Wrapper>
    {
        editMode?
        <ErrorText>{errorMessage}</ErrorText>
        :
        ''
    }

    {/* MODAL - ADD TO CALENDAR */}
    <MyModal 
        modalState={modalIsOpen}
        dualButtons={true}
        closeAction={() => {closeModal('calendar')}}
        modalTitle='Plan meal in calendar'
        modalContent={calendarModalContent}
        modalActionTitle='Add'
        modalAction={() => {addMealToPlannerHandler()}}
    />

    {/* MODAL - ADD REVIEW */}
    <MyModal 
        modalState={reviewModalIsOpen}
        dualButtons={true}
        closeAction={() => {closeModal('review')}}
        modalTitle='Add review'
        modalContent={reviewModalContent}
        modalActionTitle='Add'
        modalAction={() => {addReviewHandler()}}
    />

    {/* MODAL - PUBLISH MEAL */}
    <MyModal 
        modalState={publishModalIsOpen}
        closeAction={() => {closeModal('publish')}}
        dualButtons={true}
        modalTitle='Publich My Meal'
        modalContent={publishModalContent}
        modalActionTitle='Publish'
        modalAction={() => {publishMealHandler()}}
    />

    {/* MODAL - DELETE MEAL */}
    <MyModal 
        modalState={deleteModalIsOpen}
        closeAction={() => {closeModal('deletemeal')}}
        dualButtons={true}
        modalTitle='Delete My Meal'
        modalContent={deleteMealModalContent}
        modalActionTitle='Delete'
        modalAction={() => {deleteMealHandler()}}
    />

     {/* MODAL - MOVE MEAL */}
     <MyModal 
        modalState={moveModalIsOpen}
        closeAction={() => {closeModal('movemeal')}}
        dualButtons={true}
        modalTitle='Move My Meal'
        modalContent={moveModalContent}
        modalActionTitle='Move'
        modalAction={() => {moveMealHandler()}}
        modalError={modalError}
    />

    {/* MODAL - MOVE MEAL */}
    <MyModal 
        modalState={addLinkMMealodalIsOpen}
        closeAction={() => {closeModal('addlinkmeal')}}
        dualButtons={true}
        modalTitle='Add Meal From Link'
        modalContent={addLinkMealModalContent}
        modalActionTitle='Add'
        modalAction={() => {addLinkMealMealHandler()}}
        modalError={modalError}
    />

    <MealNav onClick={()=>{navigate(-1);}}>
        <ArrowBack sx={iconPrimary} />
        <ContentTextMediumBold style={{color:MyColors.colors.primary}}>Back to meals</ContentTextMediumBold>
    </MealNav>

    <TopContent>

        {
            params.slug?
            <img src={currentMealImage.display} alt={selectedMeal.mealName} style={{width: '100%', height: 180, objectFit: 'cover'}} /> 
            :
            <img src={currentMealImage.display} alt={selectedMeal.mealName} style={{width: '100%', height: 180, objectFit: 'cover'}} /> 
        }
        
        {
            editMode?
            <ChangeImg>
                <label className="input-group-text" htmlFor="photo" style={{color: MyColors.colors.white}}>Change Image</label>
                <input type='file' accept='image/*' name='photo' id='photo' onChange={changePhoto} style={{color: MyColors.colors.white}} />
            </ChangeImg>
            :
            ''
        }
        
        {
            editMode?
            <MealActions>
                 <MealActionIcon>
                    <MyButton type='primary' action={toggleEdit} label='Save' />
                </MealActionIcon>
            </MealActions>
            :
            <MealActions>
                {/* ONLY AVAILABLE TO LOGGED IN USER IF MEAL BELONGS TO THEM */}
                {
                    (selectedMeal.user) && (user._id === selectedMeal.user._id)?
                        <>
                        <MealActionIcon>
                            <MyButton type='primaryIcon' bgcolor={MyColors.colors.green} action={() => {openModal('publish')}} label={selectedMeal.published? 'Unpublish' : 'Publish'} iconDetails={<Publish sx={iconWhite} />} />
                        </MealActionIcon>
                        
                        <MealActionIcon>
                            <MyButton type='primaryIcon' action={toggleEdit} label='Edit' iconDetails={<Edit sx={iconWhite} />} />
                        </MealActionIcon>

                        <MealActionIcon>
                            <MyButton type='primaryIcon' action={() => {openModal('deletemeal')}} label='Delete' iconDetails={<Delete sx={iconWhite} />} />
                        </MealActionIcon>

                        <MealActionIcon>
                            <MyButton type='primaryIcon' action={()=>{openModal('movemeal')}} label='Move Meal' iconDetails={<Publish sx={iconWhite} />} />
                        </MealActionIcon>
                    </>
                        
                    :
                    ''
                }

                {/* AVAILABLE TO EVERYONE */}
                {
                    Object.keys(user).length !== 0?
                    <>
                        <MealActionIcon>
                            <MyButton type='primaryIcon' action={() => {openModal('calendar')}} label='Add to Calendar' iconDetails={<AccessAlarm sx={iconWhite} />} />
                        </MealActionIcon>

                        <MealActionIcon>
                            <MyButton type='primaryIcon' action={printMeal} label='Print' iconDetails={<Print sx={iconWhite} />} />
                        </MealActionIcon>

                        {/* <ButtonPrimary onClick={shareMeal}>Share</ButtonPrimary> */}
                        <RWebShare
                            data={{
                                text: "Check out this meal.",
                                url: "https://www.bbcgoodfood.com/recipes/next-level-chilli-con-carne",
                                sites: ['facebook', 'twitter', 'mail', 'copy'],
                                title: selectedMeal.mealName,
                            }}
                            onClick={shareMeal}
                        >
                            <MealActionIcon>
                                <MyButton type='primaryIcon' action={()=>{}} label='Share' iconDetails={<Share sx={iconWhite} />} />
                            </MealActionIcon>
                        </RWebShare>
                    </>
                    :
                    <>
                        <MealActionIcon>
                            <MyButton type='primaryIcon' action={printMeal} label='Print' iconDetails={<Print sx={iconWhite} />} />
                        </MealActionIcon>

                        <RWebShare
                            data={{
                                text: "Like humans, flamingos make friends for life",
                                url: "https://www.bbcgoodfood.com/recipes/next-level-chilli-con-carne",
                                title: selectedMeal.mealName,
                            }}
                            onClick={shareMeal}
                        >
                            <MealActionIcon>
                                <MyButton type='primaryIcon' action={()=>{}} label='Share' iconDetails={<Share sx={iconWhite} />} />
                            </MealActionIcon>
                        </RWebShare>
                        {/* <ButtonPrimary onClick={shareMeal}>Share</ButtonPrimary> */}
                    </>
                }
            </MealActions>
        }
    </TopContent>

<BottomContent>
    <LeftContent>
        {
            editMode?
            <MyButton type='primary' action={() => {openModal('addlinkmeal');}} label='Add Meal From Link' />
            :
            ''
        }
        
        <MealDetails>
            <MealDetailsSection>
            {
                editMode?
                <MyInput 
                    label="Name" 
                    inputValue={mealNameState}
                    inputId='mealname'
                    handleChange={(val) => {setMealNamestate(val.target.value)}}
                />
                :
                <MainHeading>{mealNameState}</MainHeading>
            }
            </MealDetailsSection>  

            <MealDetailsSection>
                <MealNotificationsWrapper>
                    {
                        editMode?
                        <MyInput 
                            width='small'
                            label="Cooking time" 
                            inputValue={mealPreptime} 
                            inputId='preptime'
                            handleChange={(val) => {setMealPreptime(val.target.value)}}
                        />
                        :
                        <MealNotificationBox>
                            <AccessAlarm sx={iconBlue} />
                            <ContentTextMediumBold>Cooking time: {selectedMeal.prepTime}</ContentTextMediumBold>
                        </MealNotificationBox>
                    }

                    {
                        editMode?
                        <MyInput 
                            width='small'
                            label="Serves" 
                            inputValue={mealYeilds} 
                            inputId='yeilds'
                            handleChange={(val) => {setMealYeilds(val.target.value)}}
                        />
                        :
                        <MealNotificationBox>
                            <LocalDining sx={iconBlue} />
                            <ContentTextMediumBold>Serves: {selectedMeal.yeilds}</ContentTextMediumBold>
                        </MealNotificationBox>
                    }

                    {
                        editMode?
                        <MyInput 
                            width='small'
                            label="Difficulty" 
                            inputValue={mealDifficulty}
                            inputId='difficulty'
                            handleChange={(val) => {setMealDifficulty(val.target.value)}}
                        />
                        :
                        <MealNotificationBox>
                            <Microwave sx={iconBlue} />
                            <ContentTextMediumBold>Difficulty {selectedMeal.difficulty}</ContentTextMediumBold>
                        </MealNotificationBox>
                    }
                </MealNotificationsWrapper>
            </MealDetailsSection>

            <MealDetailsSection>
                <ContentTextMediumRegular style={{color: MyColors.colors.darkgrey}}>Nutritional Information:</ContentTextMediumRegular>
                <NutritionListStyle>
                {
                    mealNutritionList.map(((item, index) => {
                        return(
                            editMode?
                                <MyCheckboxList 
                                    key={index}
                                    action={()=>{
                                        const temp = [...mealNutritionList];
                                        temp[index].status = temp[index].status? false : true;
                                        setMealNutritionList(temp);
                                    }}
                                        editMode={editMode} 
                                        checked={item.status} 
                                        label={item.name} 
                                />
                            :
                                item.status?
                                    <ContentTextMediumRegular key={index}>{item.name}</ContentTextMediumRegular>
                                :
                                    ''
                        )
                    }))
                }
                </NutritionListStyle>
            </MealDetailsSection>

            {
                editMode?
                <MyTextArea
                    label="Description" 
                    inputValue={mealDescriptionState}
                    handleChange={(val) => {setMealDescriptionState(val.target.value)}}
                    inputId='mealdesc'
                />
                :
                <div style={{minHeight: 50, overflow: 'hidden'}}>
                    <ContentTextMediumRegular style={{color: MyColors.colors.blackLight}}>{selectedMeal.mealDescription}</ContentTextMediumRegular>
                </div>
            }
        </MealDetails>

        {/* Ingredients and steps */}
        <LeftBottomWrapper>
            <IngredientsContainer>
                <ListWrapper>
                    <ContentTextLargeBold>Ingredients</ContentTextLargeBold>
                    {
                        editMode?
                        <MyButton type='primary' action={AddIngredient} label='+ Add Ingredient' />
                        :
                        ''
                    }
                    
                    {
                        mealIngredientsList.map(((item, index) => {
                            return(
                                editMode?
                                    <div key={index}>
                                        {/* <ContentTextMediumRegular>{item.ingredientID}</ContentTextMediumRegular>
                                        <MyInput
                                            inputValue={item.name}
                                            inputId={item.ingredientID}
                                            handleChange={(val) => {ChangeIngredientText(index, val.target.value)}}
                                        />
                                        <ButtonPrimary onClick={() => RemoveIngredient(index)}>Delete</ButtonPrimary> */}

                                        <MyList 
                                            listName={item.itemName} 
                                            listId = {item.id} 
                                            editStatus = {item.editStatus}
                                            active = {item.activeStatus}
                                            removeStatus = {item.removeStatus}
                                            confirmRemove = {() => {
                                                const temp = [...mealIngredientsList];
                                                temp.splice(index, 1);
                                                
                                                setMealIngredientsList(temp);
                                                setFeedbackMessage('Ingredient removed successfully');
                                            }}
                                            undo = {()=>{
                                                changeStatus('ingredients', false, index);
                                            }}
                                            toggleListEdit = {()=>{
                                                const newList = listEditHandler(mealIngredientsList, index);
                                                setMealIngredientsList(newList);
                                            }}
                                            saveListName = {async ()=>{
                                                const newList = listSaveHandler(mealIngredientsList, index);
                                                setMealIngredientsList(newList);
                                            }}

                                            removeList = {()=>{
                                                changeStatus('ingredients', true, index);
                                            }}
                                            handleListEdit={(val) => {ChangeIngredientText(index, val.target.value)}}
                                            numberOfItems = '0'
                                            sortActions={true}
                                            moveUp={()=>{
                                                const newList = moveUpInList(mealIngredientsList, index);
                                                if(newList) {
                                                    setMealIngredientsList(newList);
                                                }
                                            }}
                                            moveDown={()=>{
                                                const newList = moveDownInList(mealIngredientsList, index);
                                                if(newList) {
                                                    setMealIngredientsList(newList);
                                                }
                                            }}
                                        />
                                            </div>
                                        :
                                            <ContentTextMediumRegular key={index}>
                                                {item.itemName}
                                            </ContentTextMediumRegular>
                                    )
                                }))
                            }
                        </ListWrapper>
                    </IngredientsContainer>

                    <StepsContainer>
                        <ListWrapper>

                            <ContentTextLargeBold>Steps</ContentTextLargeBold>
                            {
                                editMode?
                                <MyButton type='primary' action={AddStep} label='+ Add Step' />
                                :
                                ''
                            }
                            
                            {
                                mealStepsList.map(((item, index) => {
                                return(
                                    editMode?
                                    <MyList
                                        key ={index}
                                        listName={item.itemName} 
                                        listId = {item.id} 
                                        editStatus = {item.editStatus}
                                        active = {item.activeStatus}
                                        removeStatus = {item.removeStatus}
                                        confirmRemove = {() => {
                                            const temp = [...mealStepsList];
                                            temp.splice(index, 1);
                                            
                                            setMealStepsList(temp);
                                            //await deleteListHandler(item.ingredientID, 'ingredient');
                                            setFeedbackMessage('Ingredient removed successfully');
                                        }}
                                        undo = {()=>{
                                            changeStatus('steps', false, index);
                                        }}
                                        toggleListEdit = {()=>{
                                            const newList = listEditHandler(mealStepsList, index);
                                            setMealStepsList(newList);
                                        }}
                                        saveListName = {async ()=>{
                                            const newList = listSaveHandler(mealStepsList, index);
                                            setMealStepsList(newList);
                                        }}
                                        removeList = {()=>{
                                            changeStatus('steps', true, index);
                                        }}
                                        handleListEdit={(val) => {ChangeStepsText(index, val.target.value)}}
                                        numberOfItems = '0'
                                        sortActions={true}
                                        moveUp={()=>{
                                            const newList = moveUpInList(mealStepsList, index);
                                            if(newList) {
                                                setMealStepsList(newList);
                                            }
                                        }}
                                        moveDown={()=>{
                                            const newList = moveDownInList(mealStepsList, index);
                                            if(newList) {
                                                setMealStepsList(newList);
                                            }
                                        }}
                                    />
                                    :
                                    <ContentTextMediumRegular key={index}>
                                        {item.itemName}
                                    </ContentTextMediumRegular>
                            )
                        }))
                    }
                </ListWrapper>
            </StepsContainer>
        </LeftBottomWrapper>
    </LeftContent>
 
    {
        editMode?
        ''
        :
        <RightContent>
            {
                !params.slug?
                ''
                :
                <ReviewsWrapper>
                    <ReviewHeader>
                        <ContentTextMediumRegular>Reviews ({mealReviews.length})</ContentTextMediumRegular>
                        <Stars numOfStars={selectedMeal.ratingAverage} clickable={false} />
                        {
                            (selectedMeal.user) && (user._id === selectedMeal.user._id)?
                            ''
                            :
                            <MyButton type='primary' action={() => {openModal('review')}} label='Add Review' />
                        }
                    </ReviewHeader>
                        {
                            mealReviews.map((item, index) => {
                                const date = getConvertedDate(item.createdAt);
                                return (
                                    <ReviewCard 
                                        key={index}
                                        username = {item.user.name}
                                        userphoto = {item.user.photo}
                                        reviewDescription = {item.reviewDescription}
                                        reviewRating = {item.rating}
                                        reviewDate = {date.TdateFormatted}
                                        stars = {item.rating}
                                    />
                                )
                            })
                        }
                </ReviewsWrapper>
            }
        </RightContent>
    }
    </BottomContent>
</Wrapper>

    return (
        Object.keys(user).length !== 0 ?
        <UserViewTemplate 
            pageContent = {contentToShow}
            selectedScreen = {location.state? location.state.screen: 'allmeals'}
        />
        :
        <GeneralViewTemplate 
            pageContent = {contentToShow}
            selectedScreen = {location.state? location.state.screen: 'allmeals'}
        />
    )
}

export default Meal