import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

import styled from 'styled-components';
import {MainHeading, ContentTextMediumRegular} from '../../constants/styles'
import MyButton from '../../components/MyButton';

const ContentWrapper = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

function ServerError() {
    const location = useLocation();
    const navigate = useNavigate();

    const ErrorTemplate = (props) => {
        return (
            <ContentWrapper>
                <MainHeading>{props.statuscode} - {props.heading}</MainHeading>
                <ContentTextMediumRegular>{props.subheading}</ContentTextMediumRegular>
                <>{props.content}</>
            </ContentWrapper>
        )
    }

    switch (location.state.code) {
        case 'ERR_NETWORK':
            return (
                <ErrorTemplate 
                    heading = 'Something went wrong'
                    statuscode = { location.state ? location.state.code : '400' }
                    subheading = { location.state ? location.state.message : 'Try again later.' }
                    content = ''
                />
            )
        case 'ERR_BAD_REQUEST':
            return (
                <ErrorTemplate 
                    heading = 'We could not find that page'
                    statuscode = { location.state ? location.state.code : '400' }
                    subheading = { location.state ? location.state.message : 'Try again later.' }
                    content = ''
                />
            )
        case 'ERR_BAD_RESPONSE':
            return (
                <ErrorTemplate 
                    heading = 'We could not find that page'
                    statuscode = { location.state ? location.state.code : '400' }
                    subheading = { location.state ? location.state.message : 'Try again later.' }
                    content = ''
                />
            )
        case 'NOT_AUTHORIZED':
            return (
                <ErrorTemplate 
                heading = 'Looks like you are not logged in'
                statuscode = { location.state ? location.state.code : '401' }
                subheading = { location.state ? location.state.message : 'Log in and try again' }
                content = {<>
                    <MyButton type='primary' action={()=>{navigate('/login')}} label='Login' />
                </>}
            />
            )
        default:
            return (
                <ErrorTemplate 
                    heading = 'Something went wrong'
                    statuscode = { location.state ? location.state.code : '400' }
                    subheading = 'Try again later.'
                    content = ''
                />
            )
    }
  }

  export default ServerError