/* eslint-disable */
import React,{useContext, useState, useEffect} from "react"
import styled from 'styled-components';
import {UserContext,} from '../store/context/user-context';
import {Link, useNavigate} from 'react-router-dom'

import {AppHeading, ButtonLink, ContentTextMediumBold} from '../constants/styles'
import {linkStyle} from '../constants/cssstyles'
import FeedbackToast from "./feedbackToast";
import {logout} from '../controllers/api/usersController'
import UserSideNav from "./userSideNav";
import headerstyles from './../css/header.css';
import UserProfileNav from "./userProfileNav";
import {checkUserStillExists} from '../controllers/api/usersController'
import {config} from '../constants/config'
import {NavLinkStyle} from '../constants/cssstyles'
import {NavItem} from '../constants/styles'

const ContentBlock = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.2rem 0;
`

const RightActions = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`

function AppHeader(props) {
    const navigate = useNavigate();
    
    const { user, setUser, feedbackMessage, setFeedbackMessage, pageName, setPageName } = useContext(UserContext);

    // Flag, which stores whether the screen is scrolled
    const [isScrolled, setScrolled] = useState(false);

    // Handler when page is scrolled
    const handleScroll = () => {
        const distanceY = window.pageYOffset || document.documentElement.scrollTop,
        shrinkOn = 250,
        headerEl = document.getElementById('js-header');

        if (distanceY > shrinkOn) {
            headerEl.classList.add("smaller");
        } else {
            headerEl.classList.remove("smaller");
        }
    }

    useEffect(() => {
        // Adding the scroll listener
        window.addEventListener('scroll', handleScroll, { passive: true });
    
        return () => {
            // Removing listener
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const authenticateUser = async() => {
        try {
            if(Object.keys(user).length === 0 ) { 
                //check if coockie exists
                const res = await checkUserStillExists();

                if(res.status === 'success') {
                    setUser(res.doc)
                }
            }
        }
        catch(err) {
            
        }
    }

    useEffect(() => {
        authenticateUser();
    },[]); 
    

    const clearFeedback = () => {
        setFeedbackMessage('')
    }

    const logoutUser = async() => {
        const res = await logout();

        if(res.status === 'success') {
            location.assign('/');
        }
        else {
            console.log(res.message);
        }
    }

    const loginUser = async() => {
        location.assign('/login');
    }

    const HeaderContent = (props) => {
        return (
            <div id='js-header' className={props.classes}>
                {
                    feedbackMessage === '' ?
                    ''
                    :
                    <FeedbackToast message={feedbackMessage} closeAction={clearFeedback} />
                }

                <ContentBlock>
                    <UserSideNav selectedScreen={pageName} />
                </ContentBlock>
                
                <ContentBlock>
                    <Link to='/' style={linkStyle}>
                        <AppHeading>My Meals - Expert Recepies</AppHeading>
                    </Link>
                </ContentBlock>

                <ContentBlock>
                    {
                        Object.keys(user).length === 0?
                            // <Link to={props.login} style={linkStyle}>
                            //     <ButtonPrimary>Login</ButtonPrimary>
                            // </Link>

                            <Link to='/login' style={NavLinkStyle}>
                                <NavItem>
                                    <ContentTextMediumBold>Login</ContentTextMediumBold>
                                </NavItem>
                            </Link>
                            :
                            <RightActions>
                                {/* <UserProfileNav photo={user.photo} /> */}
                                <div onClick={()=>{
                                    navigate(`/mymeals`);
                                }}>
                                    <img src={`${config.url.production}img/users/${user.photo}`} style={{width: 50, height: 50, borderWidth: 0.5, borderRadius: 200}} />
                                </div>

                                <ButtonLink onClick={logoutUser}>Logout</ButtonLink>
                            </RightActions>
                    }
                </ContentBlock>
            </div>
        )
    }

    if(pageName === 'allmealss') {
        return (
            // <HeaderContent classes={isScrolled ? 'header smaller' : 'header larger'} />
            <HeaderContent classes={`header ${isScrolled && 'smaller'}`} />
         )
    }
    return (
        <HeaderContent classes={'header smaller'} />
     )
    
}

export default AppHeader