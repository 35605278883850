/* eslint-disable */
import React, {useEffect, useContext} from 'react'
import {useParams, useNavigate} from 'react-router-dom'
import Calendar from 'react-calendar';

// import 'react-calendar/dist/Calendar.css';
import '../../constants/calendar/styles.css';
import styled from 'styled-components'
import {ContentTextMediumBold, ContentTextMediumRegular, ContentTextSmallRegular} from '../../constants/styles'
import ScheduledMealCard from '../../components/scheduledMealCard';
import { MyColors } from '../../constants/colors';
import { UserContext } from '../../store/context/user-context';
import ProfileDetails from '../../components/profileDetails';
import { getConvertedDate, getTodaysDate } from '../dateTimeComponent';
import ShoppingList from '../../views/shoppingList';
import Profile from '../../views/profile'
import CloseNav from '../../components/atoms/closeNav'

const MealsContainer = styled.div`
    display: flex;
    // flex: 4;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
`

const MealTypeContainer = styled.div`
    display: flex;
    flex: 1;
    width: 325px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
    padding: 0.8rem;
    border: 1px solid ${MyColors.colors.lightgrey};
    border-radius: 0.2rem;
    background-color: ${MyColors.colors.white}
`

const ContentWrapper = styled.div`
    display: flex;
    // flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem;
`

const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
`

const UserDetailsContainer = (props) => {

    const navigate = useNavigate();
    const params = useParams();
    
    const {
        user,
        selectedCalDay,
        scheduledMeals,
        setSelectedCalDay,
        removeMealFromPlanner,
        datesWithMeals,

    } = useContext(UserContext);

    const todaysdate = getTodaysDate();

    useEffect(() => {
        // PopulateScheduledMeals();
    },[selectedCalDay]);

    const onDateChange = (date) => {
        const newDate = getConvertedDate(date);
        setSelectedCalDay(newDate)
    }

    const removeMealHandler = async(scheduleId) => {
        removeMealFromPlanner(scheduleId);
    }

    function tileClassName({ date, view }) {

    const newDate = getConvertedDate(date);
    var Tdate = newDate.Tdate;

    if(view === 'month' && Tdate === selectedCalDay.Tdate){
        return  'currentActive'
    }
    else if(view === 'month' && datesWithMeals.find(dDate => dDate === Tdate)){
        return  'highlight'
    }  
    }

      function navigateToMeal(slug) {
        //redirect if not on the page already
        if(!params.slug){
            navigate(`/${slug}`);
        }
        else {
        //redirect if slug different from the one in the current url
            if(params.slug !== slug) {
                navigate(`/${slug}`);
            }
        }
      }

        const ScheduledMealsList = (props) => {
        const mealArray = props.mealTypeArray;

        return (
            <MealTypeContainer>
                <ContentTextMediumBold style={{color: MyColors.colors.black}}>{props.mealTypeName}</ContentTextMediumBold>
                {
                    mealArray.length > 0?
                    mealArray.map((item) => {
                        return (
                            <ScheduledMealCard 
                                key={item.id} 
                                title ={item.mealId.mealName} 
                                mealImg={item.mealId.mealImg} 
                                openMeal={()=>navigateToMeal(item.mealId.slug)}
                                background={item.mealId.section.sectionColor}
                                removeMeal={() => removeMealHandler(item.id)} 
                                published={item.mealId.published}
                            />
                        )
                    })
                    :
                    <ContentTextSmallRegular style={{color: MyColors.colors.blackLight}}>No meals added yet. To add a meal find a meal you like and click on the 'Add meal to calendar' option.</ContentTextSmallRegular>
                }
                </MealTypeContainer>
        )
      }

    if(props.content === 'CalendarToday') {
        return (
            <ContentWrapper>
                <Header>
                    <CloseNav closeAction={props.closeAction} />
                    <ContentTextMediumBold>My Calendar</ContentTextMediumBold>
                </Header>
                {/* <ProfileDetails 
                    size = 'large'
                    userPhoto={`http://127.0.0.1:8000/img/users/${user.photo}`} style={{width: 50, height: 50, borderWidth: 0.5, borderRadius: 200}} 
                    userName={user.name}
                    userDescription={user.description}
                    linkTo='/mymeals'
                /> */}
                <Calendar 
                    onChange={date => onDateChange(date)} 
                    value={todaysdate.Tdate}
                    className="react-calendar" 
                    tileClassName={tileClassName}
                />
                <MealsContainer>
                    <ContentTextMediumBold style={{color: MyColors.colors.black}}>{selectedCalDay.TdateFormatted} {selectedCalDay.Tdate}</ContentTextMediumBold>
                </MealsContainer>

                    <ScheduledMealsList 
                        mealTypeName = 'Breakfast'
                        mealTypeArray = {scheduledMeals.filter(item => item.mealType == 'Breakfast' && item.scheduleDate == selectedCalDay.Tdate)}
                    />

                    <ScheduledMealsList 
                        mealTypeName = 'Lunch'
                        mealTypeArray = {scheduledMeals.filter(item => item.mealType == 'Lunch' && item.scheduleDate == selectedCalDay.Tdate)}
                    />

                    <ScheduledMealsList 
                        mealTypeName = 'Dinner'
                        mealTypeArray = {scheduledMeals.filter(item => item.mealType == 'Dinner' && item.scheduleDate == selectedCalDay.Tdate)}
                    />

            </ContentWrapper>

        );
    }

    else if(props.content === 'List') {
        return (
            <ContentWrapper>
                <Header>
                    <CloseNav closeAction={props.closeAction} />
                    <ContentTextMediumBold>My Shopping List</ContentTextMediumBold>
                    {/* <ProfileDetails 
                        size = 'large'
                        userPhoto={`http://127.0.0.1:8000/img/users/${user.photo}`} style={{width: 50, height: 50, borderWidth: 0.5, borderRadius: 200}} 
                        userName={user.name}
                        userDescription={user.description}
                        linkTo='/mymeals'
                    /> */}
                </Header>
                <ShoppingList />
            </ContentWrapper>
        )
    }

    else if(props.content === 'Person') {
        return (
            <ContentWrapper>
                <Header>
                    <CloseNav closeAction={props.closeAction} />
                    <ContentTextMediumBold>My Profile</ContentTextMediumBold>
                </Header>
                {/* <ProfileDetails 
                    size = 'large'
                    userPhoto={`http://127.0.0.1:8000/img/users/${user.photo}`} style={{width: 50, height: 50, borderWidth: 0.5, borderRadius: 200}} 
                    userName={user.name}
                    userDescription={user.description}
                    linkTo='/profile'
                /> */}
                <Profile />
            </ContentWrapper>
        )
    }
    else {
        //default show nothing
        return (
            <></>
        )
    }
  }

  export default UserDetailsContainer