import React,{useLayoutEffect, useState} from "react"
import styled from 'styled-components';
import { LocalDining } from '@mui/icons-material';

import {ContentTextMediumBold, ContentTextMediumRegular} from '../constants/styles'
import { MyColors } from "../constants/colors";
import MealStars from "./mealStars";
import { iconBlue } from '../constants/cssstyles';
import {config} from '../constants/config'

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0.4rem;
    padding: 0.4rem;
    border-radius: 0.4rem;
    background-color: ${MyColors.colors.lightestgrey};
`

const ContentWrapperMobile = styled.div`
    display: flex;
    // flex: 1;
    width: 100%;
    flex-direction: column;
    justify-content: 'flex-start';
    align-items: 'flex-start';
    margin-left: 0.4rem;
    margin-right: 0.4rem;
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
    border-radius: 4px;
    background-color: ${MyColors.colors.lightestgrey};
    // box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`

const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 0.4rem;
`
const Titles = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`
const Content = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding-top: 0.2rem;
    // border-top: 1px solid ${MyColors.colors.lightgrey};
`

const Details = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0.2rem;
    border-right: 1px solid ${MyColors.colors.lightgrey};
`

const ChefCard = React.forwardRef((props, ref) =>  {

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useLayoutEffect(() => {
        function updateWidth() {
            setScreenWidth(window.innerWidth);
        }

        window.addEventListener('resize', updateWidth);
        updateWidth();
        return () => window.removeEventListener('resize', updateWidth);
    }, []);

    const CardDetails = (props) => {
        return (
            <>
                <Header>
                    <img src={`${config.url.production}img/users/${props.photo}`} style={{width: 50, height: 50, borderWidth: 0.5, borderRadius: 200}} />
                    <Titles>
                        <ContentTextMediumBold>{props.name}</ContentTextMediumBold>
                        <ContentTextMediumRegular>{props.description}</ContentTextMediumRegular>
                        <ContentTextMediumRegular>{props.description}</ContentTextMediumRegular>
                    </Titles>
                </Header>

                <Content>
                    <Details>
                        <LocalDining sx={iconBlue} />
                        <ContentTextMediumRegular>{props.numOfMeals} Meals</ContentTextMediumRegular>
                    </Details>
                    
                    <Details>
                        <MealStars numOfStars={props.ratingAverage} clickable={false} />
                    </Details>
                    
                </Content>
            </>
        )
    }

    return (
        ref?
        screenWidth > 624?
            <ContentWrapper ref={ref} onClick={props.openDetails}>
                <CardDetails 
                    name={props.name} 
                    description={props.description} 
                    numOfMeals={props.numOfMeals} 
                    photo={props.photo} 
                    openDetails={props.openDetails}
                    ratingAverage={props.ratingAverage}
                />
            </ContentWrapper>
            :
            <ContentWrapperMobile ref={ref} onClick={props.openDetails}>
                <CardDetails 
                    name={props.name} 
                    description={props.description} 
                    numOfMeals={props.numOfMeals} 
                    photo={props.photo} 
                    openDetails={props.openDetails}
                    ratingAverage={props.ratingAverage}
                />
            </ContentWrapperMobile>

            :

            screenWidth > 624?
            <ContentWrapper onClick={props.openDetails}>
                <CardDetails 
                    name={props.name} 
                    description={props.description} 
                    numOfMeals={props.numOfMeals} 
                    photo={props.photo} 
                    openDetails={props.openDetails}
                    ratingAverage={props.ratingAverage}
                />
            </ContentWrapper>
            :
            <ContentWrapperMobile onClick={props.openDetails}>
                <CardDetails 
                    name={props.name} 
                    description={props.description} 
                    numOfMeals={props.numOfMeals} 
                    photo={props.photo} 
                    openDetails={props.openDetails}
                    ratingAverage={props.ratingAverage}
                />
            </ContentWrapperMobile>
    )
})

export default ChefCard