import React from "react"
import styled from 'styled-components';

import {ContentTextMediumRegular} from '../constants/styles'
import { MyColors } from "../constants/colors";
import MyCheckbox from "./atoms/myCheckBox";

const ListContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 0.4rem;
    border-radius: 1rem;
    background-color: ${MyColors.colors.white};
`

function MyCheckboxList(props) {

    return (
        <ListContainer>
            <MyCheckbox checked={props.checked} action={props.action} />
            <ContentTextMediumRegular>{props.label}</ContentTextMediumRegular>
        </ListContainer>
    )
}

export default MyCheckboxList