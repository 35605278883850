import axios from 'axios';
import {config} from '../../constants/config';

export const addReview = async(data, mealId) => {
    
    const res = await axios({
        method: 'PATCH',
        url: `${config.url.production}api/v1/meals/${mealId}/reviews`,
        withCredentials: true,
        dataType: 'json',
        Headers: {
            'Content-Type': 'application/json',
            // 'Access-Control-Allow-Origin': '*',
        },
        data
    });
    return res.data;
}