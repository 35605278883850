import {MyColors} from '../constants/colors'
import styled from 'styled-components';
import {config} from '../constants/config';

////////////////////////////FONTS///////////////////////
//Headings
export const AppHeading = styled.h3`
    text-align: left;
    color: ${MyColors.colors.white};
    font-size: 16px;
    font-weight: 600;
    margin: 0.2rem;
    word-wrap: break-word;
`

export const MainHeading = styled.h3`
    text-align: left;
    margin: 4px 0;
    color: ${MyColors.colors.black};
    font-size: 20px;
    font-weight: 600;
    margin: 0.2rem;
    word-wrap: break-word;
`

//Content
export const ContentTextSmallRegular = styled.p`
    text-align: left;
    margin: 4px 0;
    font-size: 14px;
    font-weight: 400;
    margin: 0.2rem;
    word-wrap: break-word;
`
export const ContentTextSmallBold = styled.p`
    text-align: left;
    margin: 4px 0;
    font-size: 14px;
    font-weight: 600;
    margin: 0.2rem;
    word-wrap: break-word;
`

export const ContentTextMediumRegular = styled.p`
    text-align: left;
    margin: 4px 0;
    font-size: 16px;
    font-weight: 400;
    margin: 0.2rem;
    word-wrap: break-word;
`
export const ContentTextMediumBold = styled.p`
    text-align: left;
    margin: 4px 0;
    font-size: 16px;
    font-weight: 600;
    margin: 0.2rem;
    word-wrap: break-word;
    word-wrap: break-word;
`

export const ContentTextLargeRegular = styled.p`
    text-align: left;
    margin: 4px 0;
    font-size: 18;
    font-weight: 400;
    margin: 0.2rem;
    word-wrap: break-word;
`
export const ContentTextLargeBold = styled.p`
    text-align: left;
    margin: 4px 0;
    font-size: 18;
    font-weight: 600;
    margin: 0.2rem;
    word-wrap: break-word;
`

export const ErrorText = styled.p`
    text-align: left;
    margin: 4px 0;
    word-wrap: break-word
    width:2 200px;
    color: ${MyColors.colors.red};
    font-size: 16;
    font-weight: 400;
`

export const SuccessText = styled.p`
    text-align: left;
    margin: 4px 0;
    color: ${MyColors.colors.green};
    font-size: 16;
    font-weight: 400;
`

////////////////////////////COMPONENTS///////////////////////

export const Header = styled.div`
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    min-height: 4rem;
    padding: 0 2rem;
    background-color: ${MyColors.colors.blue};
    position: sticky;
`

export const HeaderExpanded = styled.div`
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    min-height: 24rem;
    padding: 0 2rem;
    // background-color: ${MyColors.colors.blue};
    background-image: url(${config.url.production}img/meals/default.jpg');
`

export const Content = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    min-height: 80vh;
    // margin: 0.5rem;
    padding: 0.5rem;
    background-color: ${MyColors.colors.white};
`

//NAVIGATION
export const TopNav = styled.div`
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    min-height: 4rem;
    padding: 0 2rem;
    margin: 0.4rem;
    border-radius: 1rem;
`

export const NavItem = styled.div`
    padding: 0.4rem;
    margin-left: 0.2rem;
    margin-right: 0.2rem;
    border-radius: 4rem;
    color: ${MyColors.colors.white}
    // background-color: ${MyColors.colors.white};
`

export const NavItemSelected = styled.div`
    padding: 0.4rem;
    margin-left: 0.2rem;
    margin-right: 0.2rem;
    border-radius: 4rem;
    background-color: ${MyColors.colors.white};
`

//Links and Buttons
export const Action = styled.a`
    min-width: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    margin: 0.3rem;
    text-decoration: none;
    border-radius: 0.2rem;
    color: ${MyColors.colors.white};
    background-color: ${MyColors.colors.primary};
`

export const ButtonLink = styled.button`
    display: flex;
    // flex: 1;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    margin: 0.4rem;
    border-radius: 0.2rem;
    border: 0;
    background: none;
    color: ${MyColors.colors.white};
    text-decoration: underline;
`

//Inputs

export const ContentWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    background-color: ${MyColors.colors.white};
`