import {saveSection, deleteSection} from '../api/sectionController'

export function listEditHandler(array, index) {
        const temp = [];
        for(let i = 0; i < array.length; i++) {
            if(i === index) {
                const tempItem = array[i];
                tempItem.editStatus = true;
                tempItem.activeStatus = true;
                temp.push(tempItem);
            }
            else {
                const tempItem = array[i];
                tempItem.activeStatus = false;
                temp.push(array[i]);
            }
        }
        console.log(temp)
        return temp;
    }

    export function listSaveHandler(array, index) {
        const temp = [];
        for(let i = 0; i < array.length; i++) {
            if(i === index) {
                const tempItem = array[i];
                tempItem.editStatus = false;
                tempItem.activeStatus = true;
                temp.push(tempItem);
            }
            else {
                const tempItem = array[i];
                tempItem.activeStatus = true;
                temp.push(array[i]);
            }
        }
        return temp;
    }

    export const moveUpInList = (array, index) => {
        if(index !== 0) { //there is atlease one item on top
            const temp = [...array];
            const thisItem = temp[index];
            const itemToChange = temp[index - 1];
    
            temp[index] = itemToChange;
            temp[index - 1] = thisItem;
            
            return temp;
        }
        return;
    }
    
    export const moveDownInList = (array, index) => {
        if(index !== array.length-1) { //there is atlease one item on top
            const temp = [...array];
            const thisItem = temp[index];
            const itemToChange = temp[index + 1];
    
            temp[index] = itemToChange;
            temp[index + 1] = thisItem;
            
            return temp;
        }
        return;
    }

export async function saveListHandler(listType, listArray, inputVal, id, userId) {
    switch(listType) {
        case 'section':
            try {
                const tempArray = [...listArray];
                const data = {
                    userId: userId,
                    sectionId: id,
                    sectionName: inputVal
                }
                const res = await saveSection(data);

                if(res.status === 'success') {
                    // setFeedbackMessage('Section name updated successfully');
                    tempArray.find((section) => {
                        if(section.sectionId === data.sectionId) {
                            section.editStatus = false;
                            section.sectionName = data.sectionName;
                        }
                        section.activeStatus = true;
                    })

                    return tempArray;
                }
                
            }
            catch (err) {
        
            }
    }
}

export async function deleteListHandler(id, listType) {
    try {
            switch(listType) {
                case 'section':
                    const res = await deleteSection(id);

                    if(res.status === 'success') {
                        return res.doc;
                    }
            }
        }
    catch (err) {
        console.log(err)
    }
}