import React from "react"
import styled from 'styled-components';
import { ArrowBack } from '@mui/icons-material';
import {useNavigate} from 'react-router-dom'

import { MyColors } from "../../constants/colors";
import { iconPrimary } from '../../constants/cssstyles';
import {ContentTextMediumBold} from '../../constants/styles'

const Nav = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    height: 1rem;
    border-radius: 10rem;
    // border: solid 1px ${MyColors.colors.primary};
`

function BackNav(props) {
    const navigate = useNavigate();

    return (
        <Nav onClick={()=>{navigate(-1);}}>
            <ArrowBack sx={iconPrimary} />
            <ContentTextMediumBold style={{color:MyColors.colors.primary}}>Back</ContentTextMediumBold>
        </Nav>
    )
}

export default BackNav