/* eslint-disable */
import React from "react"
import styled from 'styled-components';
import MyInput from "./MyInput";
import { MyColors } from "../constants/colors";
import { ContentTextLargeBold } from "../constants/styles";

const ContentBlock = styled.div`
    display: flex;
    // flex: 1;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
`

const FilterBox = styled.div`
    min-width: 4rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0.7rem;
    margin: 0.3rem;
    border-radius: 0.3rem;
    border: 1px solid ${MyColors.colors.lightgrey};
    color: ${MyColors.colors.white};
    background-color: ${MyColors.colors.primary};
`

function SearchFilterBar(props) {

    return (
        <ContentBlock>
            <MyInput 
                label="Search"
                showLabel = {false}
                placeholder='Search...'
                inputValue={props.searchTerm}
                inputId='searchmeal'
                handleChange={props.handleSearch}
            />  

            {/* <FilterBox>
                <ContentTextLargeBold>Filter</ContentTextLargeBold>
            </FilterBox> */}
        </ContentBlock>
    )
}

export default SearchFilterBar