import React from "react"
import styled from 'styled-components';
import { Star, StarOutline, StarHalf } from '@mui/icons-material';
import { iconSmallStar } from "../constants/cssstyles";

const StarWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
`

function MealStars(props) {
    const stars = props.numOfStars

    const StarType = ({clickable, clicked, action}) => {
        if(clicked) {
            if(clickable) {
                return (
                    <div onClick={action}><Star sx={iconSmallStar}/></div>
                )
            }
            else {
                return (
                    <Star sx={iconSmallStar} />
                )
            }
           
        }
        else {
            if(clickable) {
                return (
                    <div onClick={action}> <StarOutline sx={iconSmallStar} /></div>
                )
            }
            else {
                return (
                    <StarOutline sx={iconSmallStar} />
                )
            }
        }
    }

    if(stars === 5) {
        return (
            <StarWrapper>
                <StarType clicked={true} clickable={props.clickable} action={props.clickOne} />
                <StarType clicked={true} clickable={props.clickable} action={props.clickTwo} />
                <StarType clicked={true} clickable={props.clickable} action={props.clickThree} />
                <StarType clicked={true} clickable={props.clickable} action={props.clickFour} />
                <StarType clicked={true} clickable={props.clickable} action={props.clickFive} />
            </StarWrapper>
        )
    }
    else if(stars === 4) {
        return (
            <StarWrapper>
                <StarType clicked={true} clickable={props.clickable} action={props.clickOne} />
                <StarType clicked={true} clickable={props.clickable} action={props.clickTwo} />
                <StarType clicked={true} clickable={props.clickable} action={props.clickThree} />
                <StarType clicked={true} clickable={props.clickablese} action={props.clickFour} />
                <StarType clicked={false} clickable={props.clickable} action={props.clickFive} />
            </StarWrapper>
        )
    }
    else if(stars === 3) {
        return (
            <StarWrapper>
                <StarType clicked={true} clickable={props.clickable} action={props.clickOne} />
                <StarType clicked={true} clickable={props.clickable} action={props.clickTwo} />
                <StarType clicked={true} clickable={props.clickable} action={props.clickThree} />
                <StarType clicked={false} clickable={props.clickable} action={props.clickFour} />
                <StarType clicked={false} clickable={props.clickable} action={props.clickFive} />
            </StarWrapper>
        )
    }
    else if(stars === 2) {
        return (
            <StarWrapper>
                <StarType clicked={true} clickable={props.clickable} action={props.clickOne}  />
                <StarType clicked={true} clickable={props.clickable} action={props.clickTwo} />
                <StarType clicked={false} clickable={props.clickable} action={props.clickThree} />
                <StarType clicked={false} clickable={props.clickable} action={props.clickFour} />
                <StarType clicked={false} clickable={props.clickable} action={props.clickFive} />
            </StarWrapper>
        )
    }
    else if(stars === 1) {
        return (
        
            <StarWrapper>
                <StarType clicked={true} clickable={props.clickable} action={props.clickOne} />
                <StarType clicked={false} clickable={props.clickable} action={props.clickTwo} />
                <StarType clicked={false} clickable={props.clickable} action={props.clickThree} />
                <StarType clicked={false} clickable={props.clickable} action={props.clickFour} />
                <StarType clicked={false} clickable={props.clickable} action={props.clickFive} />
            </StarWrapper>
        )
    }
    else if(stars === 0) {
        return (
            <StarWrapper>
                <StarType clicked={false} clickable={props.clickable} action={props.clickOne} />
                <StarType clicked={false} clickable={props.clickable} action={props.clickTwo} />
                <StarType clicked={false} clickable={props.clickable} action={props.clickThree} />
                <StarType clicked={false} clickable={props.clickable} action={props.clickFour} />
                <StarType clicked={false} clickable={props.clickable} action={props.clickFive} />
            </StarWrapper>
        )
    }
    else {
        return (
            <StarWrapper>
                <StarType clicked={false} clickable={props.clickable} action={props.clickOne} />
                <StarType clicked={false} clickable={props.clickable} action={props.clickTwo} />
                <StarType clicked={false} clickable={props.clickable} action={props.clickThree} />
                <StarType clicked={false} clickable={props.clickable} action={props.clickFour} />
                <StarType clicked={false} clickable={props.clickable} action={props.clickFive} />
            </StarWrapper>
        )
    }
}

export default MealStars