import axios from 'axios';
import GetError from '../frontend/errorController';
import {config} from '../../constants/config';

export const getAllMeals = async(page) => {

    const res = await axios({
        method: 'GET',
        url: `${config.url.production}api/v1/meals?limit=10&page=${page}`,
    });
    return res.data;
}

export const getMyMeals = async(id) => {
    const res = await axios({
        method: 'GET',
        url: `${config.url.production}api/v1/users/${id}/meals`,
        withCredentials: true,
    });
    return res
}

export const getMeal = async(slug) => {
    const res = await axios({
        method: 'GET',
        url: `${config.url.production}api/v1/meals/${slug}`,
    });
    return res.data;
}

export const getMyMeal = async(slug, userId) => {
    const res = await axios({
        method: 'GET',
        url: `${config.url.production}api/v1/users/${userId}/meals/${slug}/`,
    });
    return res.data;
}

export const addMeal = async(data) => {
    const res = await axios({
        method: 'POST',
        url: `${config.url.production}api/v1/meals`,
        data,
        withCredentials: true,
        Headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
    });
    return res.data;
}

export const updateMeal = async(mealID, data) => {
    const res = await axios({
        method: 'PATCH',
        url: `${config.url.production}api/v1/meals/${mealID}`,
        withCredentials: true,
        dataType: 'json',
        Headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
            // 'Access-Control-Allow-Origin': '*',
        },
        data
    });
    return res.data;
}

export const moveMeal = async(mealID, section) => {
    const data = {
        section
    }

    const res = await axios({
        method: 'PATCH',
        url: `${config.url.production}api/v1/meals/${mealID}`,
        withCredentials: true,
        dataType: 'json',
        Headers: {
            'Content-Type': 'application/json',
            // 'Access-Control-Allow-Origin': '*',
        },
        data
    });
    return res.data;
}

export const deleteMeal = async(id) => {
    const res = await axios({
        method: 'DELETE',
        url: `${config.url.production}api/v1/meals/${id}`,
        withCredentials: true,
    });
    return res;
}

export const getScheduledMeals = async(selectedDate, userId) => {
    const res = await axios({
        method: 'GET',
        url: `${config.url.production}api/v1/users/${userId}/schedule`,
        dataType: 'json',
        headers: {
            // 'Access-Control-Allow-Origin': '*', 
            'Content-Type': 'application/json'
          },
    });
    return res;
}

export const getScheduledMeal = async(data) => {
    const res = await axios({
        method: 'GET',
        url: `${config.url.production}api/v1/users/${data.user}/schedule/${data.mealId}/${data.scheduleDate}/${data.mealType}`,
        dataType: 'json',
        headers: {
            // 'Access-Control-Allow-Origin': '*', 
            'Content-Type': 'application/json'
          },
    });
    return res;
}

export const getExistingMealtypesForMeal = async(data) => {
    const res = await axios({
        method: 'GET',
        url: `${config.url.production}api/v1/users/${data.user}/schedule/${data.mealId}/${data.scheduleDate}`,
        dataType: 'json',
        headers: {
            // 'Access-Control-Allow-Origin': '*', 
            'Content-Type': 'application/json'
          },
    });
    return res.data;
}

export const addMealToSchedule = async(data) => {
    const res = await axios({
        method: 'POST',
        url: `${config.url.production}api/v1/users/${data.user}/schedule`,
        withCredentials: true,
        dataType: 'json',
        headers: {
            // 'Access-Control-Allow-Origin': '*', 
            'Content-Type': 'application/json'
          },
        data
    });
    return res;
}

export const removeMealFromSchedule = async(scheduleId) => {
    const res = await axios({
        method: 'DELETE',
        url: `${config.url.production}api/v1/schedule/${scheduleId}`,
        withCredentials: true,
    });
    return res;
}