/* eslint-disable */
import React, {useState} from 'react'
import styled from 'styled-components';

import { MainHeading, ErrorText, SuccessText, ContentTextMediumRegular} from '../constants/styles'
import { MyColors } from '../constants/colors';
import MyInput from '../components/MyInput';
import MyButton from '../components/MyButton';
import {forgotPassword} from '../controllers/api/usersController';
import BackNav from '../components/atoms/backNav';

const Wrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const FormWrapper = styled.div`
    min-width: 20rem;    
    margin: 0.5rem;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    border-radius: 1rem;
    background-color: ${MyColors.colors.lightestgrey};
`

function ForgotPassword() {

    const [errorMesg, setErrorMsg] = useState('');
    const [successMesg, setSuccessMesg] = useState('');
    
    const forgotPasswordHandler = async () => { 
        const email = document.getElementById('email').value;

        const res = await forgotPassword(email);

        if(res.status === 'success') {
            setSuccessMesg('Password reset link has been sent to your email')
        }
        else {
            setErrorMsg(res.message)
        }
    }

    return (
        <Wrapper>
            <BackNav />
            <FormWrapper>
                <MainHeading>Enter your password</MainHeading>
                <ContentTextMediumRegular style={{color: MyColors.colors.black}}>If your email exists you will be sent a password reset link</ContentTextMediumRegular>
                    {
                        errorMesg === '' ?
                        ''
                        :
                        <ErrorText>{errorMesg}</ErrorText>
                    }

                    {
                        successMesg === '' ?
                        ''
                        :
                        <SuccessText>{successMesg}</SuccessText>
                    }
                    <MyInput 
                        label="Email" 
                        inputValue="" 
                        inputId='email'
                    />
                <MyButton type='primary' action={forgotPasswordHandler} label='Send' />
            </FormWrapper>
        </Wrapper>
    )
  }

  export default ForgotPassword