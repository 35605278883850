import React, {useState, useEffect, useContext, useRef, useCallback} from 'react'
import {useParams, useNavigate, useLocation} from 'react-router-dom'
import styled from 'styled-components';
import { ArrowBack } from '@mui/icons-material';

import { ContentTextMediumBold } from '../constants/styles';
import GeneralViewTemplate from '../components/screenTemplates/generalViewTemplate';
import { UserContext } from '../store/context/user-context';
import SearchFilterBar from '../components/searchFilterBar';
import { getMyMeals } from '../controllers/api/mealsController';
import { iconPrimary } from '../constants/cssstyles';
import { MyColors } from '../constants/colors';
import ChefCard from '../components/chefCard';
import MealCard from '../components/mealCard';
import {getChefDetails} from '../controllers/api/chefController'

const PageContent = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
`

const ContentWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
`

const MealsWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
`

const Filters = styled.div`
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
`

const BackNav = styled.div`
    display:flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top:0.4rem;
    padding-bottom:0.8rem;
`

function ChefDetails() {
    //Check if there is a user
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();

    const observer = useRef();

    const [pageNum, setPageNum] = useState(1);

    const { 
        setPageName,
        mealsContent,
        setMealsContent,
        pageName
    } = useContext(UserContext);

    const [searchTerm, setSearchTerm] = useState('');
    const [meals, setMeals] = useState([]);
    const [mealsToDisplay, setMealsToDisplay] = useState(meals);

    const [chefDetails, setChefDetails] = useState([]);

    const getUserMeals = async() => {
        const res = await getMyMeals(params.id);

        if(res.data.status === 'success') {
            const meals = res.data.doc.filter((meal) => meal.published === true)
            setMeals(meals);
            setMealsToDisplay(meals)
        }
    }
    const getChef = async() => {
        const res = await getChefDetails(params.id);

        if(res.status === 'success') {
            const chedD = {
                photo: res.doc.photo,
                name: res.doc.name,
                description: res.doc.description,
                numOfMeals: res.doc.ratingQuantity,
                ratingAverage: res.doc.ratingAverage
            }
            setChefDetails(chedD);
        }
    }

    useEffect(() => {
        //get all the meals
        document.title = 'My Meals - Expert Recipes';
        setPageName('chefs');
        getUserMeals();
        getChef();
        if(!location.state) {
            getChef();
        }
        else {
            const temp ={
                photo: location.state.photo,
                name: location.state.name,
                description: location.state.description,
                numOfMeals: location.state.ratingQuantity,
                ratingAverage: location.state.ratingAverage
            }

            setChefDetails(temp)
        }
            
    },[]);

     useEffect(() => {
        const temp = []
        const mealsToSearchFrom = meals;
            
        if(searchTerm !== ''){
            for(let i = 0; i < mealsToSearchFrom.length; i++) {
                if(mealsToSearchFrom[i].mealName.toLocaleLowerCase().includes(searchTerm)) {
                    //check if it belongs to the selected section
                    temp.push(mealsToSearchFrom[i]) 
                }
                setMealsToDisplay(temp);
            }
        }
        else {
            setMealsToDisplay(mealsToSearchFrom)

        }
    },[searchTerm]);

    function navigateToMeal(slug) {
        //redirect if not on the page already
        if(!params.slug){
            navigate(`/${slug}`, {state:{screen: 'chefs'}});
        }
        // else {
        // //redirect if slug different from the one in the current url
        //     if(params.slug !== slug) {
        //         navigate(`/${slug}`);
        //     }
        // }
      }

    const contentToShow =
    <PageContent>

    <ContentWrapper>
        <BackNav onClick={()=>{navigate(-1);}}>
            <ArrowBack sx={iconPrimary} />
            <ContentTextMediumBold style={{color:MyColors.colors.primary}}>Back</ContentTextMediumBold>
        </BackNav>

        <ChefCard
            photo={chefDetails.photo}
            name={chefDetails.name}
            description={chefDetails.description}
            numOfMeals ={chefDetails.ratingQuantity}
            ratingAverage={chefDetails.ratingAverage}
        />

        {
            (meals.length > 0) ?

            <Filters>
                <SearchFilterBar searchTerm={searchTerm} handleSearch={(val) => {setSearchTerm(val.target.value)}} />
            </Filters>
            :
            ''
        }

        {/* Meals */}
        <MealsWrapper>
            {
                (mealsToDisplay.length > 0) ?
                mealsToDisplay.map((item, index) => {
                    return (
                        <MealCard 
                            key={index} 
                            username={item.user.name}
                            userphoto={item.user.photo}
                            sectionName={item.section.sectionName}
                            sectionColor={item.section.sectionColor}
                            title={item.mealName} 
                            description={item.mealDescription.substring(item.description, 70) + '...'}
                            link={()=>navigateToMeal(item.slug)}
                            mealImg={item.mealImg}
                            numOfStars={item.ratingAverage}
                        />
                    )
                })
                :
                <ContentTextMediumBold>No meals to show</ContentTextMediumBold>
            }
        </MealsWrapper>
    </ContentWrapper>

    </PageContent>

    return (
        <GeneralViewTemplate 
            pageContent = {contentToShow}
            selectedScreen = 'chefs'
        />
    );
  }

  export default ChefDetails