/* eslint-disable */
import React,{useState, useEffect} from "react"
import styled from 'styled-components';
import {Link, useNavigate} from 'react-router-dom'

import {ContentTextMediumBold, ContentTextMediumRegular} from '../constants/styles'
import {linkStyle} from '../constants/cssstyles'
import { MyColors } from "../constants/colors";

export const Footer = styled.div`
    display: flex;
    flex: 1;
    height: 4rem;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.8rem;
    background-color: ${MyColors.colors.blackLight};
`

const ContentBlock = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0.2rem 0;
`

const RightActions = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`

function AppFooter(props) {
    const navigate = useNavigate();

    const FooterContent = (props) => {
        return (
            <Footer>   

                <ContentBlock>
                    <Link to='/' style={linkStyle}>
                        <ContentTextMediumBold style={{color:MyColors.colors.white}}>Abous Us</ContentTextMediumBold>
                    </Link>
                </ContentBlock>

                <ContentBlock>
                    <Link to='/' style={linkStyle}>
                        <ContentTextMediumRegular style={{color:MyColors.colors.white}}>Created by: App Designery</ContentTextMediumRegular>
                    </Link>
                </ContentBlock>

            </Footer>
        )
    }

    return (
        <FooterContent />
     )
    
}

export default AppFooter