import React, {useState, useEffect, useContext} from 'react'
import styled from 'styled-components';
import {Link, useParams, useNavigate} from 'react-router-dom'

import {getMySections, addNewSection} from '../controllers/api/sectionController'
import MealCard from './../components/mealCard';
import {UserContext} from '../store/context/user-context';
import {ContentTextMediumBold, ContentTextMediumRegular, ErrorText} from '../constants/styles'
import {linkStyle} from '../constants/cssstyles'
import UserViewTemplate from '../components/screenTemplates/userViewTemplate';
import MyInput from '../components/MyInput';
import { MyColors } from '../constants/colors';
import MyList from '../components/MyList'
import {listEditHandler, saveListHandler, deleteListHandler} from '../controllers/frontend/listController'
import MyModal from '../components/MyModal';
import SearchFilterBar from '../components/searchFilterBar';
import MyRadioList from '../components/myRadioList';
import ChefCard from '../components/chefCard'
import MyButton from '../components/MyButton';
import {checkUserStillExists} from '../controllers/api/usersController'

const PageContent = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
`

const MealsContent = styled.div`
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
`

const Filters = styled.div`
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
`

const FilterWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0.3rem;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    border-radius: 0.2rem;
    background-color: ${MyColors.colors.white};
`

const ActionsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin: 0.4rem;
    padding-top: 1rem;
`

const MySelectStyle = styled.select`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 0 0.4rem;
    padding: 1rem;
    border: 1px solid ${MyColors.colors.lightgrey};
    border-radius: 0.2rem;
    background-color: ${MyColors.colors.white};
`

const ModalContentSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 1rem;
    // padding: 0.4rem;
    // border: 1px solid ${MyColors.colors.lightgrey};
    // border-radius: 0.2rem;
`

const ModalSectionList = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
`

const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 1rem;
    border-bottom: 1px solid ${MyColors.colors.lightgrey};
`

const EditSectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid ${MyColors.colors.lightgrey};
    border-radius: 0.2rem;
    padding: 4px;
    margin: 0 0.5rem;
`

function MyMeals() {
    const params = useParams();
    const navigate = useNavigate();

    //Check if there is a user
    const { 
        user, 
        setUser,
        setFeedbackMessage,
        pageName,
        setPageName,
        myMeals,
        setMyMeals,
        mySections,
        setMySections,
        updateMySection,
        deleteMySection,
        changeSectionStatus
    } = useContext(UserContext);
    
    const [allMeals, setAllMeals] = useState([]);
    // const [allSections, setAllSections] = useState([]);
    const [selectedSection, setSelectedSection] = useState('All');
    const [selectedSectionMove, setSelectedSectionMove] = useState('All');

    const [displayedMeals, setDisplayedMeals] = useState([])

    const [modalIsOpen, setIsOpen] = useState(false);
    const [addMealModal, setAddMealModal] = useState(false);

    const [sectionNameInput, setSectionNameInput] = useState('My Section');

    const randomColors = ['#FFEBD9', '#DAE6FF', '#F6DCFF', '#E8FFDD', '#FFE3E3', '#DBF8FF', '#E0DFFF', '#FDD9F5', '#FFF9DC',]
    const randomSectionImages = ['defaultimage2.jpg', 'defaultimage3.jpg', 'defaultimage4.jpg' ]

    const [errorMessage, setErrorMessage] = useState('');

    const [searchTerm, setSearchTerm] = useState('');

    const [all, setAll] = useState(true);
    const [draft, setDraft] = useState(false);
    const [published, setPublished] = useState(false);

    const authenticateUser = async() => {
        try {
            if(Object.keys(user).length === 0 ) { 
                //check if coockie exists
                const res = await checkUserStillExists();

                if(res) {
                    await setUser(res.doc)
                }
                else {
                    navigate('/404')
                    // AppError(404, 'You are not logged in')
                    // AppError(404, 'You are not logged in')
                }
            }
        }
        catch(err) {
            
        }
    }

    const getMyMealsHandler = async() => {
        try {
            //const res = await getMyMeals(user._id);
            let res = null;
            
            if(user._id) {
                res = await getMySections(user._id);
            }
            else {
                const userNew = await checkUserStillExists();

                if(userNew && userNew.status === 'success') {
                    setUser(userNew.doc)
                    res = await getMySections(userNew.doc._id);
                }
            }

            console.log(res)
            if(res && res.status === 'success') { 
                // const tempArray = []
                // const tempArrayToSave = []

                const tempMealArray = []
                const tempSectionArray = []
                
                for(let i=0; i < res.doc.length; i++) {
                    
                    //Populate the meals
                    res.doc[i].meals.forEach(meal => {
                        tempMealArray.push(meal) 
                    });

                    //Populate the sections
                    const temp = {
                        sectionId: res.doc[i]._id,
                        sectionName: res.doc[i].sectionName,
                        editStatus: false,
                        activeStatus: true,
                        removeStatus: false,
                        numberOfMeals: res.doc[i].meals.length > 0? res.doc[i].meals.length : 0
                    }
                    tempSectionArray.push(temp)
                    

                    // if(!tempArray.includes(res.data.doc[i].section.sectionName)) {
                    //     const temp = {
                    //         sectionId: res.data.doc[i].section._id,
                    //         sectionName: res.data.doc[i].section.sectionName
                    //     }
                    //     tempArray.push(res.data.doc[i].section.sectionName)
                    //     tempArrayToSave.push(temp)
                    // }
                }
                setMySections(tempSectionArray)
                setAllMeals(tempMealArray);
                setMyMeals(tempMealArray);
                setDisplayedMeals(tempMealArray);
            }
            
        }
        catch (err) {
            navigate('/error', {
                state: {
                    name: err.name,
                    message: err.message,
                    code: err.code
                }
            });
        }
    }

    useEffect(() => {
        //get all the meals
        document.title = 'My meals';
        setPageName('mymeals');
        getMyMealsHandler();
    },[]);

    const filterMyMeals = () => {
        let tempArray = [...allMeals];

        if(selectedSection !== 'All'){
            tempArray = allMeals.filter((meal) => meal.section._id === selectedSection)
        }

        if(draft) {
            const tempDraft = tempArray.filter((meal) => meal.published === false);
            setDisplayedMeals(tempDraft);
        }
        else if(published) {
            const tempPublished = tempArray.filter((meal) => meal.published === true);
            setDisplayedMeals(tempPublished);
        }
        else {
            setDisplayedMeals(tempArray);
        }
    }

    //Sort meals displayed when a different section is selected in the dropdown
    useEffect(() => {
        filterMyMeals()
    },[selectedSection, all, draft, published]);

        //Sort meals displayed based on search
        useEffect(() => {
            const temp = []
            const mealsToSearchFrom = selectedSection === 'All'? allMeals : allMeals.filter((meal) => meal.section._id === selectedSection)
                
            if(searchTerm !== ''){
                for(let i = 0; i < mealsToSearchFrom.length; i++) {
                    if(mealsToSearchFrom[i].mealName.includes(searchTerm)) {
                        //check if it belongs to the selected section
                        if(selectedSection === 'All') {
                            temp.push(mealsToSearchFrom[i])
                        }
                        else if(selectedSection === mealsToSearchFrom[i].section._id) {
                            temp.push(mealsToSearchFrom[i])
                        }  
                    }
                setDisplayedMeals(temp);
                }
            }
            else {
                setDisplayedMeals(mealsToSearchFrom)

            }
        },[searchTerm]);

    const changeSelectedSection = (value) => {
        setSelectedSection(value.target.value)
    }

    const addSection = async() => {
        try {
            const data = {
                sectionName: sectionNameInput,
                sectionColor: randomColors[Math.floor(Math.random()*randomColors.length)],
                sectionIcon: randomSectionImages[Math.floor(Math.random()*randomSectionImages.length)],
                user: user._id
            }
            
            const res = await addNewSection(data);

            if(res.status === 'success') {
                setFeedbackMessage('New section added successfully');
                setErrorMessage('')

                const temp = {
                    sectionId: res.doc._id,
                    sectionName: res.doc.sectionName,
                    editStatus: false,
                    activeStatus: true,
                    removeStatus: false
                }

                updateMySection(temp);

                // if(redirect) {
                //     navigate(`add/${data.sectionName}`);
                // }
            }
        }
        catch(err) {
            setErrorMessage(err.message);
        }
    }

    function openModal(modalName) {
        //Open the modal
        document.body.style.overflow = "hidden";
        
        if(modalName === 'section') {
            setIsOpen(true);
        }
        else if(modalName === 'newmeal') {
            setSelectedSectionMove(mySections[0].sectionId)
            setAddMealModal(true);
        }
    }
    
      function closeModal(modalName) {
        document.body.style.overflow = "auto";
        
        if(modalName === 'section') {
            setIsOpen(false);
        }
        else if(modalName === 'newmeal') {
            setAddMealModal(false);
        }
    }

    const addMealModalContentToDisplay = 
      <div style={{flex:1, flexWrap: 'wrap'}}>
        {
            mySections.length > 0 ?
            <>
                <ContentTextMediumRegular>Please select a section to add the meal to.</ContentTextMediumRegular>
                <MySelectStyle value = {selectedSectionMove} onChange={(value)=>{setSelectedSectionMove(value.target.value)}}>
                    {
                        mySections.map((item) => {
                            return (
                                <option key={item.sectionId} value={item.sectionId}>{item.sectionName+' ('+item.numberOfMeals+')'}</option>
                            )
                        })
                    }
                </MySelectStyle>
            </>
            :
            <ContentTextMediumBold>You need to add atleast one section before you can start adding meals</ContentTextMediumBold>
        }
      </div>

      const modalContentToDisplay =  
      <div style={{flexDirection: 'column'}}>
      {/* ADD SECTION CONTAINER */}
      <ModalContentSection>
             <ContentTextMediumRegular>Add a new section</ContentTextMediumRegular>
             {
                 errorMessage !== ''?
                 <ErrorText>{errorMessage}</ErrorText>
                 :
                 ''
             }
             <div style={{display: 'flex', flexDirection: 'row'}}>
             <MyInput 
                width = 'small'
                label="Section name"
                showLabel = {false}
                placeholder='My section'
                inputValue={sectionNameInput}
                inputId='sectionName'
                handleChange={(val) => {setSectionNameInput(val.target.value)}}
             />  
             <MyButton type='primary' action={() => {addSection();}} label='Add' />
             </div>
     </ModalContentSection>

     {/* DISPLAY ALL SECTIONS */}
     {
        mySections.length > 0?
        <ModalContentSection>
         <ContentTextMediumBold>All my sections</ContentTextMediumBold>
         {
             mySections.map((item, index) => {
                 return (
                     <ModalSectionList key = {item.sectionId}>

                     <MyList 
                         listName={item.sectionName} 
                         listId = {item.sectionId} 
                         editStatus = {item.editStatus}
                         active = {item.activeStatus}
                         removeStatus = {item.removeStatus}
                         confirmRemove = {async() => {
                             const itemToDelete = await deleteListHandler(item.sectionId, 'section');
                             deleteMySection(itemToDelete);
                             setFeedbackMessage('Section removed successfully');
                         }}
                         undo = {()=>{
                             changeSectionStatus('remove', false, index);
                         }}
                         toggleListEdit = {()=>{
                             setSectionNameInput(item.sectionName)
                             const newList = listEditHandler(mySections, index);
                             setMySections(newList);
                         }}
                         saveListName = {async ()=>{
                             const newList = await saveListHandler('section', mySections, sectionNameInput, item.sectionId, user._id);
                             setMySections(newList);
                         }}
                         removeList = {()=>{
                             changeSectionStatus('remove', true, index);
                         }}
                         handleListEdit={(val) => {setSectionNameInput(val.target.value)}}
                         numberOfItems = {item.numberOfMeals}
                     />
                     </ModalSectionList>
                        )
                    })
                }
            </ModalContentSection>
            :
            ''
     }
 </div>

      function navigateToMeal(slug) {
        //redirect if not on the page already
        if(!params.slug){
            navigate(`/${slug}`, {state:{screen: 'mymeals'}});
        }
        // else {
        // //redirect if slug different from the one in the current url
        //     if(params.slug !== slug) {
        //         navigate(`/${slug}`);
        //     }
        // }
      }
    
    const contentToShow = 
    <PageContent>

        {/* MODAL - EDIT SECTIONS */}
        <MyModal 
            modalState={modalIsOpen}
            closeAction={() => closeModal('section')}
            modalTitle='Edit My Sections'
            error={errorMessage}
            modalContent={modalContentToDisplay}
        />

         {/* MODL - ADD MEAL */}
         <MyModal 
            modalState={addMealModal}
            closeAction={() => closeModal('newmeal')}
            dualButtons={true}
            modalAction={()=>{navigate(`add/${selectedSectionMove}`); closeModal('newmeal')}}
            modalActionTitle='Add Meal'
            modalTitle='Add New Meal'
            error={errorMessage}
            modalContent={addMealModalContentToDisplay}
        />

        <Header>
            <ChefCard
                photo={user.photo}
                name={user.name}
                description={user.description}
                numOfMeals ={2}
            />

            <FilterWrapper>
                <EditSectionContainer>
                    <ContentTextMediumBold style={{color: MyColors.colors.blackLight}}>Sections</ContentTextMediumBold>
                    {
                        mySections.length > 0?
                        <>
                            <MyButton type='primary' action={() => openModal('section')} label='Edit Sections' />
                            
                            {/* <MySelectStyle value = {selectedSection} onChange={changeSelectedSection}>
                                <option key='All' value='All'>All</option>
                            {
                                mySections.map((item) => {
                                    return (
                                        <option key={item.sectionId} value={item.sectionId}>{item.sectionName+' ('+item.numberOfMeals+')'}</option>
                                    )
                                })
                            }
                            </MySelectStyle> */}
                        </>
                        :
                        <MyButton type='primary' action={() => openModal('section')} label='Add new section' />
                    }
                </EditSectionContainer>

                <EditSectionContainer>
                <ContentTextMediumBold style={{color: MyColors.colors.blackLight}}>Meals</ContentTextMediumBold>
                    {
                        // (selectedSection !== 'All') ?
                            // <Link to={`add/${selectedSection}`} style={linkStyle}>
                                <MyButton 
                                    type='primary' 
                                    action={() => {openModal('newmeal')}}
                                    label='Add new meal'
                                />
                            // </Link>
                    
                        // :
                        // <ContentTextMediumRegular>Choose a section to add a meal to it.</ContentTextMediumRegular>
                    }
                </EditSectionContainer>
            </FilterWrapper>
        </Header>

        <ActionsWrapper>
            {
                <Filters>
                    <SearchFilterBar searchTerm={searchTerm} handleSearch={(val) => {setSearchTerm(val.target.value)}} />

                    <MySelectStyle value = {selectedSection} onChange={changeSelectedSection}>
                        <option key='All' value='All'>All</option>
                        {
                            mySections.map((item) => {
                                return (
                                    <option key={item.sectionId} value={item.sectionId}>{item.sectionName+' ('+item.numberOfMeals+')'}</option>
                                )
                            })
                        }
                    </MySelectStyle>
                </Filters>
            }
        </ActionsWrapper>

        <ActionsWrapper>
            <MyRadioList
                action={()=>{
                    setAll(true);
                    setDraft(false);
                    setPublished(false);
                }}
                editMode={true} 
                checked={all} 
                label='All' 
            />
            <MyRadioList
                action={()=>{
                    setAll(false);
                    setDraft(true);
                    setPublished(false);
                }}
                editMode={true} 
                checked={draft} 
                label='Draft' 
            />
            <MyRadioList
                action={()=>{
                    setAll(false);
                    setDraft(false);
                    setPublished(true);
                }}
                editMode={true} 
                checked={published} 
                label='Published' 
            />
        </ActionsWrapper>
        <MealsContent>

        {
            (displayedMeals.length > 0) ?
                displayedMeals.map((item => {
                    return (
                        <MealCard 
                            key={item.mealName} 
                            username={item.user.name}
                            userphoto={item.user.photo}
                            sectionName={item.section.sectionName}
                            sectionColor={item.section.sectionColor}
                            title={item.mealName} 
                            draft={item.published}
                            description={item.mealDescription.substring(item.description, 70) + '...'}
                            link={()=>navigateToMeal(item.slug)}
                            mealImg={item.mealImg}
                            numOfStars={item.ratingAverage}
                        />
                    )
                }))
                :
                <>
                    <ContentTextMediumBold>No meals found.</ContentTextMediumBold>
                </>    
        }
        </MealsContent>
    </PageContent>

    return (
        <UserViewTemplate 
            pageContent = {contentToShow}
            selectedScreen = 'mymeals'
        />
    );
  }

  export default MyMeals