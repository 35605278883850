import axios from 'axios';
import cheerio from 'react-native-cheerio';
import {NutritionList} from '../../constants/nutritionInfoList'

export async function AddMealFromBBCGoodFood(mealToAddURLInput) {
    let state = [];
    let tempIngList = [];

    let methodList = [];
    let methodList2 = [];

    let nutritionListTemp = [...NutritionList];

    let mealName = '';
    let prepTime = '';
    let mealDesc = '';
    let mealYeilds = '';
    let mealImage = '';
    let difficulty = '';
    
    try {
        let response = await axios.get(mealToAddURLInput);
        let $ = await cheerio.load(response.data);
        
        const mealTitle = $("#__next > div.default-layout > main > div > section > div > div.post-header__body.oflow-x-hidden > div.headline.post-header__title.post-header__title--masthead-layout > h1")
        mealName = mealTitle.text();

        const prepTimeTemp = $("#__next > div.default-layout > main > div > section > div > div.post-header__body.oflow-x-hidden > ul.post-header__row.post-header__planning.list.list--horizontal > li:nth-child(1) > div > div.icon-with-text__children > ul > li:nth-child(1) > span:nth-child(2) > time")
        prepTime = prepTimeTemp.text();

        const tempDesc = $("#__next > div.default-layout > main > div > section > div > div.post-header__body.oflow-x-hidden > div.editor-content.mt-sm.pr-xxs.hidden-print")
        mealDesc = tempDesc.text();

        const tempYields = $("#__next > div.default-layout > main > div > section > div > div.post-header__body.oflow-x-hidden > ul.post-header__row.post-header__planning.list.list--horizontal > li:nth-child(3) > div > div.icon-with-text__children")
        mealYeilds = tempYields.text();

        const tempDifficulty = $("#__next > div.default-layout > main > div.post.recipe > section > div > div.post-header__body.oflow-x-hidden > ul.post-header__row.post-header__planning.list.list--horizontal > li:nth-child(2) > div > div.icon-with-text__children")
        difficulty = tempDifficulty.text();

        const tempImage = $("#__next > div.default-layout > main > div > section > div > div.post-header__image-container > div > div > div > picture > img")
        mealImage = tempImage.attr("src");

        //Nutrition list
        $("#__next > div.default-layout > main > div.post.recipe > section > div > div.post-header__body.oflow-x-hidden > ul.terms-icons-list.d-flex.post-header__term-icons-list.mt-sm.hidden-print.list.list--horizontal > li"
        ).each((i, e) => {
            const nitem = $(e).text().trim();
            console.log(nitem)
            const itemIndex = NutritionList.findIndex((item) => item.name === nitem);

            if(itemIndex !== -1) {
                nutritionListTemp[itemIndex].status = true;
            }
        })

        //Get ingredients
        for(let i = 0; i < 10; i++) {
            const test = $("#__next > div.default-layout > main > div.post.recipe > div > div.layout-md-rail > div.layout-md-rail__primary > div.post__content > div:nth-child("+i+") > div.js-piano-recipe-instructions > div > section > section:nth-child(2) > ul > li")

            if(test.length > 0) {
                test.children((i, e) => {
                    state.push($(e).text().trim())
                })
                for(let i =0; i < state.length; i++) {
                    const qty = state[i].match(/(\d+)/)
                    let qtyToAdd = 0
                    
                    let remQty = state[i]
                    if(qty) {
                        remQty = state[i].replace(qty[0], '')
                        qtyToAdd = qty[0]
                    } else {
                        qtyToAdd = 0
                    }
                    
                    const tempItem = {
                        id: i,
                        itemName: state[i],
                        qty: qtyToAdd,
                        activeStatus: true,
                        editStatus: false,
                        removeStatus: false
                    }
                    tempIngList.push(tempItem) 
                }
                // setIngredientsList(tempIngList)
            }
        }

        //Get the steps
        for(let i = 0; i < 10; i++) {
            const test = $("#__next > div.default-layout > main > div.post.recipe > div > div.layout-md-rail > div.layout-md-rail__primary > div.post__content > div:nth-child("+i+") > div.js-piano-recipe-instructions > div > div > section > div > ul")
            
            if(test.length > 0) {
                test.children((i, e) => {
                    methodList.push($(e).text().trim())
                })
                for(let j =0; j < methodList.length; j++) {
                    const tempItem = {
                        id: j,
                        itemName: methodList[j],
                        activeStatus: true,
                        editStatus: false,
                        removeStatus: false
                    }
                    methodList2.push(tempItem)
                }
                // setStepsList(methodList2)
            }
        }

        const data = {
            mealName,
            prepTime,
            mealDesc,
            mealYeilds,
            mealImage,
            difficulty,
            nutritionListTemp,
            methodList2,
            methodList,
            tempIngList,
            state
        }
        return data;
    }
    catch(e) {
        console.log(e);
    }
  }