import React from "react"
import styled from 'styled-components';

import {ContentTextSmallRegular} from '../constants/styles'
import { MyColors } from "../constants/colors";
import MyButton from "./MyButton";

const ToastWrapper = styled.div`
    display: flex;
    width: '100%
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 0.8rem;
    min-width: 20rem;
    padding: 0.3rem;
    margin: 0 auto;
    position: fixed;
    top: 20px;
    z-index: 1;
    background-color: ${MyColors.colors.black}
`

function FeedbackToast(props) {
    return (
        <ToastWrapper>
            <ContentTextSmallRegular style={{color: MyColors.colors.white}}>
                {props.message}
            </ContentTextSmallRegular>

            <ContentTextSmallRegular style={{color: MyColors.colors.white}}>
                <MyButton type='primary' action={props.closeAction} label='OK' />
            </ContentTextSmallRegular>
        </ToastWrapper>
    )
}

export default FeedbackToast