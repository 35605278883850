import React from "react"

import {MyColors} from '../constants/colors'
import styled from 'styled-components';

import MyInput from "./MyInput";
import { ContentTextMediumRegular } from "../constants/styles";
import {Edit, Delete, Save, ArrowDownward, ArrowUpward} from '@mui/icons-material';
import {iconBlue, iconDisabled} from '../constants/cssstyles'
import MyCheckbox from "./atoms/myCheckBox";
import MyButton from "./MyButton";

const ListContainer = styled.div`
    display: flex;
    flex:1;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0.4rem;
    border: 1px solid ${MyColors.colors.lightgrey};
    border-radius: 0.2rem;
    background-color: ${MyColors.colors.white};
    margin: 0.4rem 0;
`

const ListDetailsContainerTop = styled.div`
    display: flex;
    flex:1;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.2rem 0;
`

const ListDetailsContainerBottom = styled.div`
    display: flex;
    flex:1;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 0.2rem 0;
`

const EditDeleteContainer = styled.div`
    display: flex;
    flex:1;
    width: 100%;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    padding: 0.2rem 0;
`

const UndoContianer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.4rem;
    margin: 0.4rem;
`

const ArrowsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
`

const ArrowsContainer = styled.div`
    padding: 0.2rem;
    margin-left: 0.2rem;
    margin-right: 0.2rem;
`
  

function MyList(props) {
    const editStatus = props.editStatus
    const active = props.active
    const remove = props.removeStatus
    const checkbox = props.checkbox

    const UndoComponent = () => {
        return (
            <UndoContianer>
                <ContentTextMediumRegular>Are you sure you want to remove this section?</ContentTextMediumRegular>
                <ContentTextMediumRegular>There are {props.numberOfItems} meals in this section that will also be deleted.</ContentTextMediumRegular>
                <MyButton type='primary' action={props.undo} label='Undo' />
                <MyButton type='primary' action={props.confirmRemove} label='Yes'  />
            </UndoContianer>
        )
    }

    if(editStatus){
        return (
            <ListContainer>               
                    <MyInput 
                        showLabel = {false}
                        inputValue={props.listName}
                        inputId={props.listId}
                        // handleChange={(val) => {setSearchTerm(val.target.value)}}
                        handleChange={props.handleListEdit}
                    /> 

{
                    remove?
                        <UndoComponent />
                    :
                        active?
                            <EditDeleteContainer>
                                <ArrowsContainer onClick={props.removeList}>  
                                    <Delete sx={iconBlue} />
                                </ArrowsContainer>

                                <ArrowsContainer onClick={props.saveListName}>
                                    <Save sx={iconBlue} />
                                </ArrowsContainer>
                            </EditDeleteContainer>
                        :
                            <EditDeleteContainer>
                                <ArrowsContainer>  
                                    <Delete sx={iconDisabled} />
                                </ArrowsContainer>

                                <ArrowsContainer>  
                                    <Save sx={iconDisabled} />
                                </ArrowsContainer>
                            </EditDeleteContainer>
                    } 
            </ListContainer>
        )
    }
    else {
        return (
            <ListContainer style={{backgroundColor: props.background}}>
                <ListDetailsContainerTop>
                    
                    <ContentTextMediumRegular style={{color: MyColors.colors.black}}>{props.listName}</ContentTextMediumRegular>

                    {
                        props.checkbox?
                            <MyCheckbox checked={props.checked} action={props.action} />
                            :
                            ''
                    }

                </ListDetailsContainerTop>
                
                <ListDetailsContainerBottom>
                    {
                            props.sortActions?
                            remove?
                            ''
                            :
                                <ArrowsWrapper>
                                    <ArrowsContainer onClick={props.moveUp}>
                                        <ArrowUpward sx={iconBlue} />
                                    </ArrowsContainer>

                                    <ArrowsContainer onClick={props.moveDown}>
                                        <ArrowDownward sx={iconBlue} />
                                    </ArrowsContainer>
                                </ArrowsWrapper>
                            :
                            ''
                        }

                        {
                            remove?
                            <UndoComponent />
                            :
                                active?
                                <ArrowsWrapper>
                                    <ArrowsContainer onClick={props.removeList}>  
                                        <Delete sx={iconBlue} />
                                    </ArrowsContainer>

                                    <ArrowsContainer onClick={props.toggleListEdit}>
                                        <Edit sx={iconBlue} />
                                    </ArrowsContainer>
                                </ArrowsWrapper>
                                :
                                <ArrowsWrapper>
                                    <ArrowsContainer>  
                                        <Delete sx={iconDisabled} />
                                    </ArrowsContainer>

                                    <ArrowsContainer>  
                                        <Edit sx={iconDisabled} />
                                    </ArrowsContainer>
                                </ArrowsWrapper>
                        }
                    </ListDetailsContainerBottom>
                
            </ListContainer>
        )
    }
}

export default MyList