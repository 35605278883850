/* eslint-disable */
import React, {useState} from 'react'
import { useParams } from "react-router-dom";
import styled from 'styled-components';

import { MainHeading, ErrorText} from '../constants/styles'
import { MyColors } from '../constants/colors';
import MyInput from '../components/MyInput';
import {resetPassword} from '../controllers/api/usersController'
import MyButton from '../components/MyButton';

const Wrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const FormWrapper = styled.div`
    min-width: 20rem;    
    margin: 0.5rem;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    border-radius: 1rem;
    background-color: ${MyColors.colors.lightestgrey};
`

function ResetPassword() {

    const params = useParams();
    const [errorMesg, setErrorMsg] = useState('')
    
    const resetPasswordHandler = async () => { 
        const password = document.getElementById('newpassword').value;
        const passwordConfirm = document.getElementById('confirmnewpassword').value;

        const res = await resetPassword(params.id, password, passwordConfirm);

        if(res.status === 'success') {
            // saveUser(res.data.user)
            location.assign('/login');
        }
        else {
            setErrorMsg(res.message)
        }
    }

    return (
        <Wrapper>
            <FormWrapper>
                <MainHeading>Choose a new password</MainHeading>
                    {
                        errorMesg === '' ?
                        ''
                        :
                        <ErrorText>{errorMesg}</ErrorText>
                    }
                    <MyInput 
                        label="New Password" 
                        inputValue="" 
                        inputId='newpassword'
                    />
                    <MyInput 
                        label="Confirm New Password" 
                        inputValue="" 
                        inputId='confirmnewpassword'
                    />
                <MyButton type='primary' action={resetPasswordHandler} label='Reset' />
            </FormWrapper>
        </Wrapper>
    )
  }

  export default ResetPassword