import React from "react"
import {Link} from 'react-router-dom'

import {MyColors} from '../constants/colors'
import styled from 'styled-components';
import {linkStyle} from '../constants/cssstyles'
import {ButtonPrimary, ContentTextMediumRegular, ContentTextLargeBold, ContentTextMediumBold, ContentTextSmallRegular} from '../constants/styles'
import ProfileDetails from "./profileDetails";
import MealStars from "./mealStars";
import {config} from '../constants/config'

const ReviewContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.4rem;
    margin: 0.4rem;
    border-radius: 0.6rem;
    background-color: ${MyColors.colors.lightestgrey};
`

const HeadWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`

function ReviewCard(props) {

    return (
        <ReviewContainer>

            <HeadWrapper>
                <MealStars numOfStars={props.reviewRating} clickable={false} /> 
                <ProfileDetails 
                    size='small'
                    userPhoto={`${config.url.production}img/users/${props.userphoto}`} 
                    userName={props.username}
                />
            </HeadWrapper>

            <ContentTextSmallRegular style={{color: MyColors.colors.black}}>{props.reviewDescription}</ContentTextSmallRegular>
            <ContentTextSmallRegular style={{color: MyColors.colors.darkgrey}}>{props.reviewDate}</ContentTextSmallRegular>
        </ReviewContainer>
    )
}

export default ReviewCard