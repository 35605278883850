import React, {useState, useEffect, useContext, useRef, useCallback} from 'react'
import {useParams, useNavigate} from 'react-router-dom'
import styled from 'styled-components';

import MealCard from './../components/mealCard';
import { ContentTextMediumBold } from '../constants/styles';
import GeneralViewTemplate from '../components/screenTemplates/generalViewTemplate';
import MyInput from '../components/MyInput';
import useMealSearch from '../hooks/useMealsSearch';
import { UserContext } from '../store/context/user-context';
import SearchFilterBar from '../components/searchFilterBar';

const PageContent = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
`

const MealsContent = styled.div`
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
`

const Filters = styled.div`
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
`

function AllMeals() {
    //Check if there is a user
    const navigate = useNavigate();
    const params = useParams();

    const observer = useRef();

    const [pageNum, setPageNum] = useState(1);

    const { 
        setPageName,
        mealsContent,
        setMealsContent,
        pageName
    } = useContext(UserContext);

    const {
        meals,
        loading,
        error,
        hasMore
    } = useMealSearch(pageNum);

    const [searchTerm, setSearchTerm] = useState('');

    const lastMealRef = useCallback(meal => {
        if(loading) return;

        if(observer.current) observer.current.disconnect();

        observer.current = new IntersectionObserver(meals => {
            if(meals[0].isIntersecting && hasMore) {
                setPageNum(prev => prev+1);
            }
        })

        if(meal) observer.current.observe(meal);
    }, [loading, hasMore]);

    const filterMeals = () => {
        const filteredMeals = [];
        if(searchTerm !== '') {
            for(let i = 0; i < meals.length; i++) {
                if(meals[i].mealName.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())) {
                    filteredMeals.push(meals[i]);
                }
            }
            setMealsContent(filteredMeals);
        }
        else {
            setMealsContent(meals);
        }
    }

    useEffect(() => {
        //get all the meals
        document.title = 'My Meals - Expert Recipes';
        setPageName('allmeals');
        filterMeals();
        //getInitialMeals();
    },[]);

    useEffect(() => {
        filterMeals();
    },[searchTerm, pageNum]);

    // useEffect(() => {
    //    GetUserDetails() 
    // },[pageReloaded]);

    // async function GetUserDetails() {
    //     const res = await getUser();
        
    //     if(res.status === 'success') {
    //         setUserState(res.doc);
    //         setPageReloaded(false);
    //     }
    //     else {
    //         //console.log(res.message)
    //     }
    // }

    // useEffect(() => {
    //     window.onbeforeunload = (event) => {
    //         const e = event || window.event;
    //         // // Cancel the event
    //         //e.preventDefault();
    //         //navigate('/')
    //         if (e) {
    //         //     //auto lodad current page using react-router-dom so contexts state is not lost
    //             navigate('/')
    //         // //e.returnValue = ''; // Legacy method for cross browser support
    //         }
    //         //return ''; // Legacy method for cross browser support
    //     };
        
    // }, []);

    function navigateToMeal(slug) {
        //redirect if not on the page already
        if(!params.slug){
            navigate(`/${slug}`, {state: {screen: 'allmeals'}});
        }
        // else {
        // //redirect if slug different from the one in the current url
        //     if(params.slug !== slug) {
        //         navigate(`/${slug}`);
        //     }
        // }
      }

    const contentToShow =
    <PageContent>

    <Filters>
        <SearchFilterBar searchTerm={searchTerm} handleSearch={(val) => {setSearchTerm(val.target.value)}} />
    </Filters>

    <MealsContent>

        {
        (mealsContent.length > 0) ?
            mealsContent.map(((item, index) => {
                if(mealsContent.length === index + 1) { //the last element
                    return (
                    <MealCard 
                        ref={lastMealRef}
                        key={index} 
                        username={item.user.name}
                        userphoto={item.user.photo}
                        sectionName={item.section.sectionName}
                        sectionColor={item.section.sectionColor}
                        title={item.mealName} 
                        description={item.mealDescription.substring(item.description, 70) + '...'}  
                        link={()=>navigateToMeal(item.slug)}
                        mealImg={item.mealImg}
                        numOfStars={item.ratingAverage}
                    />
                    )
                }
                return (
                    <MealCard 
                        key={index} 
                        username={item.user.name}
                        userphoto={item.user.photo}
                        sectionName={item.section.sectionName}
                        sectionColor={item.section.sectionColor}
                        title={item.mealName} 
                        description={item.mealDescription.substring(item.description, 70)+'...'} 
                        link={()=>navigateToMeal(item.slug)}
                        mealImg={item.mealImg}
                        numOfStars={item.ratingAverage}
                    />
                )
            }))
            :
            <>
                <ContentTextMediumBold>No meals found.</ContentTextMediumBold>
            </> 
        }
        </MealsContent>
        <div>{loading && 'Loading...'}</div>
        <div>{error && 'Error'}</div>
    </PageContent>
    return (
        <GeneralViewTemplate 
            pageContent = {contentToShow}
            selectedScreen = 'allmeals'
        />
    );
  }

  export default AllMeals