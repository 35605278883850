import React from "react"
import styled from 'styled-components';
import {ContentTextMediumRegular} from '../constants/styles'
import { MyColors } from "../constants/colors";

const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`

const TextArea = styled.textarea`
    display: flex;
    flax:1;
    justify-content: flex-start;
    align-items: center;
    min-height: 5rem;
    min-width: 300px;
    padding: 0.4rem;
    margin: 0.4rem 0;
    border-radius: 0.4rem;
    box-shadow: none;
    color: ${MyColors.colors.black};
`

function MyTextArea(props) {
    return (
        <InputWrapper>
            <ContentTextMediumRegular style={{color: MyColors.colors.darkgrey}}>{props.label}</ContentTextMediumRegular>
            <TextArea
                onChange={props.handleChange} 
                defaultValue={props.inputValue}
                type="text" 
                id={props.inputId}
            />
        </InputWrapper>
    )
}

export default MyTextArea