const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
const months = [
    {
        index: '01',
        label: 'January'
    },
    {
        index: '02',
        label: 'Febriary'
    },
    {
        index: '03',
        label: 'March'
    },
    {
        index: '04',
        label: 'April'
    },
    {
        index: '05',
        label: 'May'
    },
    {
        index: '06',
        label: 'June'
    },
    {
        index: '07',
        label: 'July'
    },
    {
        index: '08',
        label: 'August'
    },
    {
        index: '09',
        label: 'September'
    },
    {
        index: '10',
        label: 'October'
    },
    {
        index: '11',
        label: 'November'
    },
    {
        index: '12',
        label: 'December'
    }
]

export function getConvertedDate(dateTime) {

    const todaysdate = new Date(dateTime)
    var Tdate = todaysdate.getFullYear() + '-' + months[todaysdate.getMonth()].index + '-' + ((todaysdate.getDate().toString().length >1)? todaysdate.getDate() : '0'+todaysdate.getDate())
    var TdateFormatted =  days[todaysdate.getDay()] + ', ' + months[todaysdate.getMonth()].label + ' ' + todaysdate.getDate() + ' ' + todaysdate.getFullYear()

    const dateConverted = {
        Tdate,
        TdateFormatted
    }
    return dateConverted;
}

export function getTodaysDate() {

    const todaysdate = new Date()
    var Tdate = todaysdate.getFullYear() + '-' + months[todaysdate.getMonth()].index + '-' + ((todaysdate.getDate().toString().length >1)? todaysdate.getDate() : '0'+todaysdate.getDate())
    var TdateFormatted =  days[todaysdate.getDay()] + ', ' + months[todaysdate.getMonth()].label + ' ' + todaysdate.getDate() + ' ' + todaysdate.getFullYear()

    const dateConverted = {
        Tdate,
        TdateFormatted
    }
    return dateConverted;
}