/* eslint-disable */
import React,{useContext, useState, useLayoutEffect} from "react"
import { useNavigate } from "react-router-dom";
import styled from 'styled-components';

import {UserContext,} from '../store/context/user-context';
import { iconBlue, iconWhite } from '../constants/cssstyles';
import { CalendarToday, List, Person } from '@mui/icons-material';
import { MyColors } from "../constants/colors";
import UserDetailsContainer from '../components/screenTemplates/userDetailsContainer';

const ContentBlock = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin: 0.5rem;
    padding: 0.2rem 0;
    position: fixed;
    z-index: 1;
    top: 4.7rem;
    right: 0rem;
`

const NavigationActions = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`

const NavigationActionDefault = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-right: 0.5rem;
    padding: 0.5rem;

    border-radius: 0.4rem;
    // border-top-right-radius: 0.4rem;
    // border-bottom-right-radius: 0.4rem;
    background-color: ${MyColors.colors.blue}
`

const NavigationActionActive = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-right: 0.5rem;
    padding: 0.5rem;

    // border-radius: 0.4rem;
    border-top-left-radius: 0.4rem;
    border-top-right-radius: 0.4rem;
    background-color: ${MyColors.colors.lightBlue};
`

const PageContent = styled.div`
    display:flex;
    flex: 1;
    justify-content: flex-start;
    align-items: flex-start;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    background-color: ${MyColors.colors.lightBlue};
    width: 380px;
    scroll-behaviour: smooth;
    overflow-y: scroll;
    overflow-x: hidden;
    white-space: nowrap;
    position: absolute;
    top: 2.7rem;
    right: 0rem;
    z-index: 1;
`

function UserProfileNav(props) {
    const navigate = useNavigate();

    const [screenHeight, setScreenHeight] = useState(window.innerHeight);
        
    const { 
        user,
        calendarTab,
        setCalendarTab,
        personTab,
        setPersonTab,
        listTab,
        setListTab,
        selectedTab,
        setSelectedTab
     } = useContext(UserContext);

    // const [calendarTab, setCalendarTab] = useState(false);
    // const [personTab, setPersonTab] = useState(false);
    // const [listTab, setListTab] = useState(false);
    
    // const [selectedTab, setSelectedTab] = useState('');

    const NavigationActionType = (props) => {

        useLayoutEffect(() => {
            function updateHeight() {
                setScreenHeight(window.innerHeight);
            }
    
            window.addEventListener('resize', updateHeight);
            updateHeight();
            return () => window.removeEventListener('resize', updateHeight);
        }, []);

        return (
            props.status?
                <NavigationActionActive>
                    <IconToUse type={props.type} status={props.status} />
                </NavigationActionActive>
            :
                <NavigationActionDefault>
                    <IconToUse type={props.type} status={props.status} />
                </NavigationActionDefault>
        )
    }

    const IconToUse = (props) => {
        if(props.type === 'CalendarToday') {
            return  props.status? <CalendarToday sx={iconBlue} /> : <CalendarToday sx={iconWhite} />
        }
        else if(props.type === 'List') {
            return  props.status? <List sx={iconBlue} /> : <List sx={iconWhite} />
        }
        else if(props.type === 'Person') {
            return  props.status? <Person sx={iconBlue} /> : <Person sx={iconWhite} />
        }
    }
//console.log(calendarTab)

    function changeTab(tabType) {

        setSelectedTab(tabType)

        if(tabType === 'CalendarToday') {
            setCalendarTab(true);
            setListTab(false);
            setPersonTab(false);
        }
        else if(tabType === 'List') {
            setCalendarTab(false);
            setListTab(true);
            setPersonTab(false);
        }
        else if(tabType === 'Person') {
            setCalendarTab(false);
            setListTab(false);
            setPersonTab(true);
        }
    }

    return (
        <ContentBlock>
            {
                Object.keys(user).length === 0?
                ''
                :
                <>
                    <NavigationActions>
                        <div onClick={() => {
                            changeTab('CalendarToday');
                        }}>
                            <NavigationActionType type='CalendarToday' status={calendarTab} />
                        </div>
                        <div onClick={() => {
                            changeTab('List');
                        }}>
                            <NavigationActionType type='List' status={listTab} />
                        </div>
                        <div onClick={() =>{
                            changeTab('Person');
                        }}>
                            <NavigationActionType type='Person' status={personTab} />
                        </div>
                    </NavigationActions>

                
                    {
                        selectedTab !== ''?
                        <PageContent style={{height: screenHeight - 150}}>
                            <UserDetailsContainer 
                                content = {selectedTab} 
                                closeAction={()=>{
                                    setSelectedTab('');
                                    setCalendarTab(false);
                                    setListTab(false);
                                    setPersonTab(false);
                                }} 
                            />
                        </PageContent> 
                        :
                        ''
                    }
                
            </>
            }
            {/* <div onClick={()=>{
                navigate(`/mymeals`);
            }}>
                <img src={`http://127.0.0.1:8000/img/users/${user.photo}`} style={{width: 50, height: 50, borderWidth: 0.5, borderRadius: 200}} />
            </div> */}
        </ContentBlock>
    )
}

export default UserProfileNav