import { useEffect, useState, useContext } from "react";
import { useNavigate } from 'react-router-dom';

import { getAllMeals } from '../controllers/api/mealsController'
import { UserContext } from '../store/context/user-context';

const useMealSearch = (pageNumber) => {

    const navigate = useNavigate();

    const { 
        setMealsContent
    } = useContext(UserContext);
    
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [meals, setMeals] = useState([]);
    const [hasMore, setHasMore] = useState(false);

    async function getMeals() {
        const controller = new AbortController();
        const {signal} = controller;

        try {
            const res = await getAllMeals(pageNumber, {signal});

            if(res && res.status === 'success') {
                setMeals(prev => [...prev, ...res.doc]);
                setMealsContent(prev => [...prev, ...res.doc]);
                
                setHasMore(res.doc.length);
                setLoading(false);
            }
            else if(res && res.status === 'fail') {
                setLoading(false);
                setError(true);

                navigate('/error', {
                    state: {
                        name: 'Not Found',
                        message: res.data.message,
                        code: '400'
                    }
                });
            }
        }
        catch(err) {
            setLoading(false);
            setError(true);

            navigate('/error', {
                state: {
                    name: err.name,
                    message: err.message,
                    code: err.code
                }
            });
        }

        return () => controller.abort();
    }

    useEffect(() => {
        setLoading(true);
        setError(false);
        
        getMeals();
    }, [pageNumber]);

    return {loading, error, meals, hasMore};
}

export default useMealSearch