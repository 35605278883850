export const MyColors = {
    colors: {
        white: '#ffffff',
        black: '#1B1B1B',
        blackLight: '#2B2B2B',

        primary: '#913145',
        blue: '#3C5280',
        lightBlue: '#DAE6FF',

        backgroundLightBlue: '#DAE6FF',

        green: '#52803C',
        
        link: '#8D1900',
        linkSecondary: '#3C5280',
        lightgrey: '#D9D9D9',
        lightestgrey: '#f6f6f6',
        mediumgrey: '#B2B2B2',
        darkgrey: '#696969',

        yellow: '#EFC901',

        red: '#D22323',

        disabled: '#898989',
        tabDisabled: '#4A4A4A'
    }
}