/* eslint-disable */
import React, {useState, useEffect, useContext} from 'react'
import UserViewTemplate from '../components/screenTemplates/userViewTemplate';
import Calendar from 'react-calendar';
// import 'react-calendar/dist/Calendar.css';
import './../constants/calendar/styles.css';
import styled from 'styled-components'

import {ButtonPrimary, ContentTextMediumBold, ContentTextMediumRegular} from '../constants/styles'
import {getScheduledMeals, removeMealFromSchedule} from '../controllers/api/mealsController'
import ScheduledMealCard from '../components/scheduledMealCard';
import { MyColors } from '../constants/colors';
import { UserContext } from '../store/context/user-context';
import ProfileDetails from '../components/profileDetails';

const MealsContainer = styled.div`
    display: flex;
    flex: 3;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    // margin-top: 1.5rem;
    // margin-bottom: 1rem;
`

const MealTypeContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
    padding: 0.8rem;
    border: 1px solid ${MyColors.colors.lightgrey};
    border-radius: 0.2rem;
`

const ContentWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`

const LeftContent = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`

const RightContent = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 1rem;
    margin-righ: 1rem;
`

function MyCalendar() {
    
    const {
        user, 
        setFeedbackMessage,

        selectedCalDay,
        setSelectedCalDay,
        selectedCalDayBreakfastMeals,
        setSelectedCalDayBreakfastMeals,
        selectedCalDayLunchMeals,
        setSelectedCalDayLunchMeals,
        selectedCalDayDinnerMeals,
        setSelectedCalDayDinnerMeals
    } = useContext(UserContext);


    const [initialDate, changeDate] = useState(new Date());

    const todaysdate = new Date()
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    const months = [
        {
            index: '01',
            label: 'January'
        },
        {
            index: '02',
            label: 'Febriary'
        },
        {
            index: '03',
            label: 'March'
        },
        {
            index: '04',
            label: 'April'
        },
        {
            index: '05',
            label: 'May'
        },
        {
            index: '06',
            label: 'June'
        },
        {
            index: '07',
            label: 'July'
        },
        {
            index: '08',
            label: 'August'
        },
        {
            index: '09',
            label: 'September'
        },
        {
            index: '10',
            label: 'October'
        },
        {
            index: '11',
            label: 'November'
        },
        {
            index: '12',
            label: 'December'
        }
    ]

     var Tdate = todaysdate.getFullYear() + '-' + months[todaysdate.getMonth()].index + '-' + ((todaysdate.getDate().length >1)? todaysdate.getDate() : todaysdate.getDate())
     var TdateFormatted =  days[todaysdate.getDay()] + ', ' + months[todaysdate.getMonth()].label + ' ' + todaysdate.getDate() + ' ' + todaysdate.getFullYear()

    const [selectedDate, setSelectedDate] = useState(Tdate);
    const [selectedDateFormatted, setSelectedDateFormatted] = useState(TdateFormatted);
    const [datesWithMeals, setDatesWithMeals] = useState(['2023-10-18', '2023-10-19']);

    const [allScheduledMeals, setAllScheduledMeals] = useState();
    const [breakfastMeals, setBreakfastMeals] = useState(allScheduledMeals? allScheduledMeals.filter(item => item.mealType == 'Breakfast' && item.scheduleDate == selectedDate) : []);
    const [lunchMeals, setLunchMeals] = useState([]);
    const [dinnerMeals, setDinnerMeals] = useState([]);

    const setScheduledMeals = async() => {
        try{
            const res = await getScheduledMeals(selectedDate, user._id);

            if(res.data.status === 'success') {
                const meals = res.data.doc;

                setDatesWithMeals(res.data.mealDates)

                //Populate meals arrays
                const selectedDateMeals = meals.filter(item => item.selectedDate === selectedDate);
                setAllScheduledMeals(selectedDateMeals)

                setBreakfastMeals(meals.filter(item => item.mealType == 'Breakfast' && item.scheduleDate == selectedDate));
                setLunchMeals(meals.filter(item => item.mealType == 'Lunch' && item.scheduleDate == selectedDate));
                setDinnerMeals(meals.filter(item => item.mealType == 'Dinner' && item.scheduleDate == selectedDate));
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        //get all the meals
        setScheduledMeals();
    },[selectedDate]);

    const onDateChange = (date) => {
        const newDate = new Date(date)
        var Tdate = newDate.getFullYear() + '-' + months[newDate.getMonth()].index + '-' + newDate.getDate()
        var TdateFormatted =  days[newDate.getDay()] + ', ' + months[newDate.getMonth()].label + ' ' + newDate.getDate() + ' ' + newDate.getFullYear()
        
        setSelectedDate(Tdate);
        setSelectedDateFormatted(TdateFormatted);
    }

    const removeMealHandler = async(scheduleId) => {
        try {
            const res = await removeMealFromSchedule(scheduleId);

            if(res.data.status === 'success') {
                setScheduledMeals();
                setFeedbackMessage('Meal removed from planner');
            }
        }
        catch(err) {
            console.log(err);
        }
    }

      function tileClassName({ date, view }) {
        const newDate = new Date(date)
        var Tdate = newDate.getFullYear() + '-' + months[newDate.getMonth()].index + '-' + newDate.getDate()
        
        if(view === 'month' && datesWithMeals.find(dDate => dDate === Tdate)){
            return  'highlight'
           }
      }

    const contentToShow = 
        <ContentWrapper>
            <ProfileDetails 
                size = 'large'
                userPhoto={`http://127.0.0.1:8000/img/users/${user.photo}`} style={{width: 50, height: 50, borderWidth: 0.5, borderRadius: 200}} 
                userName={user.name}
                userDescription={user.description}
                linkTo='/profile'
            />
            <Calendar 
                onChange={date => onDateChange(date)} 
                value={initialDate}
                className="react-calendar" 
                tileClassName={tileClassName}
            />
            <MealsContainer>
                <ContentTextMediumBold>{selectedDateFormatted}</ContentTextMediumBold>
                <ContentTextMediumBold>{selectedDate}</ContentTextMediumBold>
            </MealsContainer>
            {/* Breakfast list */}
            <MealTypeContainer>
            <ContentTextMediumBold>Breakfast</ContentTextMediumBold>
            {/* <ButtonPrimary onClick={(()=>{})}>+ Add meal</ButtonPrimary> */}
            {
                breakfastMeals.length > 0?
                breakfastMeals.map((item) => {
                    return (
                        <ScheduledMealCard 
                            key={item.id} 
                            title ={item.mealId.mealName} 
                            mealImg={item.mealId.mealImg} 
                            openMeal={item.mealId.slug} 
                            removeMeal={() => removeMealHandler(item.id)} 
                        />
                    )
                })
                :
                <ContentTextMediumRegular>No meals added yet. To add a meal find a meal you like and click onm the 'Add meal to calendar' option.</ContentTextMediumRegular>
            }
            </MealTypeContainer>

            {/* Lunch list */}
            <MealTypeContainer>
            <ContentTextMediumBold>Lunch</ContentTextMediumBold>
            {
                lunchMeals.length > 0?
                lunchMeals.map((item) => {
                    return (
                        <ScheduledMealCard 
                            key={item.id} 
                            title ={item.mealId.mealName} 
                            mealImg={item.mealId.mealImg} 
                            openMeal={() => {}} 
                            removeMeal={() => removeMealHandler(item.id)}  
                        />
                    )
                })
                :
                <ContentTextMediumRegular>No meals added yet. To add a meal find a meal you like and click onm the 'Add meal to calendar' option.</ContentTextMediumRegular>
            }
            </MealTypeContainer>

            <MealTypeContainer>
            <ContentTextMediumBold>Dinner</ContentTextMediumBold>
            {
                dinnerMeals.length > 0?
                dinnerMeals.map((item) => {
                    return (
                        <ScheduledMealCard 
                            key={item.id} 
                            title ={item.mealId.mealName} 
                            mealImg={item.mealId.mealImg} 
                            openMeal={() => {}} 
                            removeMeal={() => removeMealHandler(item.id)}  
                        />
                    )
                })
                :
                <ContentTextMediumRegular>No meals added yet. To add a meal find a meal you like and click onm the 'Add meal to calendar' option.</ContentTextMediumRegular>
            }
            </MealTypeContainer>
        </ContentWrapper>

    return (
        <UserViewTemplate 
            heading='Calendar'
            pageContent = {contentToShow}
        />
    );
  }

  export default MyCalendar