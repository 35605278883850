import React from "react"
import styled from 'styled-components';
import { Close } from '@mui/icons-material';
import {useNavigate} from 'react-router-dom'

import { MyColors } from "../../constants/colors";
import { iconPrimary } from '../../constants/cssstyles';
import {ContentTextMediumBold} from '../../constants/styles'

const Nav = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 10rem;
    padding: 0.4rem 0.2rem;
    // border: solid 1px ${MyColors.colors.primary};
`

function CloseNav(props) {
    const navigate = useNavigate();

    return (
        <Nav onClick={props.closeAction}>
            <Close sx={iconPrimary} />
        </Nav>
    )
}

export default CloseNav