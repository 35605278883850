import React,{useLayoutEffect, useState} from "react"

import {MyColors} from '../constants/colors'
import styled from 'styled-components';
import {ContentTextSmallRegular, ContentTextLargeBold} from '../constants/styles'
import ProfileDetails from "./profileDetails";
import Stars from "./mealStars";
import { ArrowForwardIos } from '@mui/icons-material';
import { iconBlue } from '../constants/cssstyles';
import {config} from '../constants/config';

const MealCardWrapper = styled.div`
    display: grid;
    grid-gap: 1rem;
    column-gap: 1rem; //This adjusts the space between the columns
    row-gap: 1rem; //This adjusts the space between the rows
    grid-template-columns: 16rem; //This adjusts the width of the columns
    margin: 0.8rem;
    border-radius: 4px;
    background-color: ${MyColors.colors.white};
    // box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`

const MealCardWrapperMobile = styled.div`
    display: flex;
    // flex: 1;
    width: 100%;
    flex-direction: column;
    justify-content: 'flex-start';
    align-items: 'flex-start';
    margin-left: 0.4rem;
    margin-right: 0.4rem;
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
    border-radius: 4px;
    background-color: ${MyColors.colors.white};
    // box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`

const UserHeaderContainer = styled.div`
    display: flex; 
    flex-direction: row;  
    justify-content: flex-start;
    align-items: center;
    margin: 0.2rem;
    background-color: rgb(255, 255, 255, 0.8);
    border-radius: 1rem;
`

const MealDetailsContainer = styled.div`
    display: flex; 
    flex-direction: column;  
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
    padding: 0.3rem;
`

const Header = styled.div`
    display: flex; 
    flex-direction: column;  
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    position: relative;
`

const HeaderContent = styled.div`
    display: flex;
    flex:1;
    flex-direction: row;  
    justify-content: space-between;
    align-items: space-between;
    margin: 0.2rem;
    position: absolute;
    bottom: 0;
`

const Footer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`

const Description = styled.div`
    // word-wrap: break-word;
    min-height: 3rem;
`

const DraftChip = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.1rem;
    position: absolute;
    top: 0.4rem;
    right: 0.4rem;
    border-radius: 0.4rem;
    background-color: ${MyColors.colors.green};
`

const MealCard = React.forwardRef((props, ref) => {

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useLayoutEffect(() => {
        function updateWidth() {
            setScreenWidth(window.innerWidth);
        }

        window.addEventListener('resize', updateWidth);
        updateWidth();
        return () => window.removeEventListener('resize', updateWidth);
    }, []);

    const UserDetailsHeadingComp = () => {
        return (
            <UserHeaderContainer>
                <ProfileDetails 
                    size='small'
                    userPhoto={`${config.url.production}img/users/${props.userphoto}`} 
                    userName={props.username}
                />
            </UserHeaderContainer>
        )
    }

    const body = (
        <div onClick={props.link}>
            <Header>
                <img src={`${config.url.production}img/meals/${props.mealImg}`} style={{width: '100%', height: 100, backgroundColor:'pink', objectFit: 'cover', borderTopLeftRadius: 4, borderTopRightRadius: 4}} />
                {
                    props.draft === false&&
                    <DraftChip>
                        <ContentTextSmallRegular style={{color: MyColors.colors.white}}>Draft</ContentTextSmallRegular>
                    </DraftChip>
                }
                <HeaderContent>
                    <UserDetailsHeadingComp username = {props.username} />
                </HeaderContent>
            </Header>
            
            <MealDetailsContainer>
                <ContentTextLargeBold 
                    style={{
                        color: MyColors.colors.black,
                        width: '100%',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}>
                    {props.title}
                </ContentTextLargeBold>

                <Description>
                    <ContentTextSmallRegular 
                        style={{
                            color: MyColors.colors.darkgrey,
                            width: '100%',
                            whiteSpace: 'wrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}>
                        {props.description}
                    </ContentTextSmallRegular>
                </Description>

                <Footer>
                    {/* <div style={{color:props.sectionColor}}> */}
                            {/* <ContentTextMediumBold>{props.sectionName}</ContentTextMediumBold> */}
                    {/* </div> */}
                    <Stars numOfStars={props.numOfStars} clickable={false} />
                    <ArrowForwardIos sx={iconBlue} />
                </Footer>
            </MealDetailsContainer>
        </div>
    )

    return (
        screenWidth > 624?
            ref?
            <MealCardWrapper ref={ref}>{body}</MealCardWrapper>
            :
            <MealCardWrapper>{body}</MealCardWrapper>
        :
            ref?
                <MealCardWrapperMobile ref={ref}>{body}</MealCardWrapperMobile>
                :
                <MealCardWrapperMobile>{body}</MealCardWrapperMobile>
    )
})

export default MealCard