import React from "react"
import styled from 'styled-components';

import { MyColors } from "../../constants/colors";

const BoxDefault = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 1rem;
    height: 1rem;
    border-radius: 10rem;
    border: solid 1px ${MyColors.colors.primary};
`

const BoxChecked = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 1rem;
    height: 1rem;
    border-radius: 10rem;
    background-color: ${MyColors.colors.primary};
`

function MyRadio(props) {

    return (
        props.checked?
                <BoxChecked onClick={props.action} />
            :
                <BoxDefault onClick={props.action} />
    )
}

export default MyRadio