import axios from 'axios';
import {config} from '../../constants/config';

export const addNewSection = async(data) => {
    const res = await axios({
        method: 'POST',
        url: `${config.url.production}api/v1/sections`,
        withCredentials: true,
        dataType: 'json',
        Headers: {
            'Content-Type': 'application/json',
            // 'Access-Control-Allow-Origin': '*',
        },
        data
    });
    return res.data;
}

export const saveSection = async(data) => {
    const sectionId = data.sectionId

    const res = await axios({
        method: 'PATCH',
        url: `${config.url.production}api/v1/sections/${sectionId}`,
        withCredentials: true,
        dataType: 'json',
        Headers: {
            'Content-Type': 'application/json',
            // 'Access-Control-Allow-Origin': '*',
        },
        data: { sectionName: data.sectionName}
    });
    return res.data;
}

export const deleteSection = async(sectionId) => {

    const res = await axios({
        method: 'DELETE',
        url: `${config.url.production}api/v1/sections/${sectionId}`,
        withCredentials: true,
    });
    return res.data;
}

export const getMySections = async(userId) => {
    const res = await axios({
        method: 'GET',
        url: `${config.url.production}api/v1/users/${userId}/sections`,
        withCredentials: true,
        dataType: 'json',
        headers: {
            'Content-Type': 'application/json'
          },
    });
    return res.data
}