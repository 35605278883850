/* eslint-disable */
import React, {useState} from 'react'
import { useParams } from "react-router-dom";
import styled from 'styled-components';

import {MainHeading, ErrorText} from '../constants/styles'
import { MyColors } from '../constants/colors';
import MyInput from '../components/MyInput';
import {signupUser} from '../controllers/api/usersController'
import BackNav from '../components/atoms/backNav';
import MyButton from '../components/MyButton';

const ContentWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 20rem;    
    margin: 0.5rem;
    padding: 2rem;
    border-radius: 1rem;
    background-color: ${MyColors.colors.lightestgrey};
`

function Register() {

    const [errorMesg, setErrorMsg] = useState('')
    
    const registerHandler = async () => { 
        const name = document.getElementById('name').value;
        const email = document.getElementById('email').value;
        const password = document.getElementById('password').value;
        const passwordConfirm = document.getElementById('confirmnewpassword').value;

        const res = await signupUser(name, email, password, passwordConfirm);

        if(res.status === 'success') {
            location.assign('/');
        }
        else {
            setErrorMsg(res.message)
        }
    }

    return (
        <ContentWrapper>
            <BackNav />
            <FormWrapper>

                <MainHeading>Register</MainHeading>
                    {
                        errorMesg === '' ?
                        ''
                        :
                        <ErrorText>{errorMesg}</ErrorText>
                    }
                    <MyInput 
                        label="Name" 
                        inputValue="" 
                        inputId='name'
                    />
                    <MyInput 
                        label="Email" 
                        inputValue="" 
                        inputId='email'
                    />
                    <MyInput 
                        label="Password" 
                        inputValue="" 
                        inputId='password'
                    />
                    <MyInput 
                        label="Confirm New Password" 
                        inputValue="" 
                        inputId='confirmnewpassword'
                    />
                <MyButton type='primary' action={registerHandler} label='Register' />
            </FormWrapper>
        </ContentWrapper>
    )
  }

  export default Register