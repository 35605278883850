/* eslint-disable */
import React, {useEffect, useContext, useState} from 'react'
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import {Content} from '../../constants/styles'
import { iconBlue } from '../../constants/cssstyles';
import UserSideNav from '../../components/userSideNav';
import {UserContext} from '../../store/context/user-context'
import UserDetailsContainer from './userDetailsContainer';
import { MyColors } from '../../constants/colors';
import { CalendarToday, List, Person } from '@mui/icons-material';
import {listEditHandler, moveDownInList, moveUpInList} from '../../controllers/frontend/listController';

// import AppError from '../../controllers/errorController';

const LeftContent = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    padding-right:1rem;
`

const RightContent = styled.div`
    display: flex;
    flex: 0.5;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: 400px;
    // width: 180px;
    // position: sticky;
    // top:0;
`

const NavigationActions = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
`

const PageContent = styled.div`
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    background-color: ${MyColors.colors.lightestgrey};
    width: 380px;
    min-height: 400px;
`

const NavigationActionDefault = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 0.5rem;
    padding: 0.5rem;

    border-top-right-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;
    background-color: ${MyColors.colors.lightgrey}
`

const NavigationActionActive = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 0.5rem;
    padding: 0.5rem;

    border-top-right-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;
    background-color: ${MyColors.colors.lightestgrey}
`

function UserViewTemplate(props) {

    const navigate = useNavigate();
    const {user} = useContext(UserContext);

    const [calendarTab, setCalendarTab] = useState(true);
    const [personTab, setPersonTab] = useState(false);
    const [listTab, setListTab] = useState(false);
    const [selectedTab, setSelectedTab] = useState('CalendarToday');

    const AppError = (status, message) => {
        switch(status) {
            case 404: {
                //do something
                navigate('/error',
                    {state: {message}}
                )
                //navigate('/')
            }
            default: {
                //do something
            }
        }
        
    } 

    // const authenticateUser = async() => {
    //     if(Object.keys(user).length === 0 ) {    
    //         //navigate('/404')
    //         // AppError(404, 'You are not logged in')
    //         AppError(404, 'You are not logged in')
    //     }
    // }

    // useEffect(() => {
    //     authenticateUser();
    // },[]);

    const IconToUse = (props) => {
        if(props.type === 'CalendarToday') {
            return  <CalendarToday sx={iconBlue} />
        }
        else if(props.type === 'List') {
            return  <List sx={iconBlue} />
        }
        else if(props.type === 'Person') {
            return  <Person sx={iconBlue} />
        }
    }

    function changeTab(tabType) {

        setSelectedTab(tabType)

        if(tabType === 'CalendarToday') {
            setCalendarTab(true);
            setListTab(false);
            setPersonTab(false);
        }
        else if(tabType === 'List') {
            setCalendarTab(false);
            setListTab(true);
            setPersonTab(false);
        }
        else if(tabType === 'Person') {
            setCalendarTab(false);
            setListTab(false);
            setPersonTab(true);
        }
    }

    const NavigationActionType = (props) => {
        return (
            props.status?
                <NavigationActionActive>
                    <IconToUse type={props.type} />
                </NavigationActionActive>
            :
                <NavigationActionDefault>
                    <IconToUse type={props.type} />
                </NavigationActionDefault>
        )
    }

    return (
        <Content>
            <LeftContent>
                {/* <MainHeading>{props.heading}</MainHeading> */}
                {/* <UserSideNav selectedScreen={props.selectedScreen} /> */}
                {props.pageContent}
            </LeftContent>

            {/* {
                 Object.keys(user).length === 0?
                 ''
                 :
                <RightContent>
                    <PageContent>
                        <UserDetailsContainer content = {selectedTab} />
                    </PageContent> 

                    <NavigationActions>
                        <div onClick={() => changeTab('CalendarToday')}>
                            <NavigationActionType type='CalendarToday' status={calendarTab} />
                        </div>
                        <div onClick={() => changeTab('List')}>
                            <NavigationActionType type='List' status={listTab} />
                        </div>
                        <div onClick={() => changeTab('Person')}>
                            <NavigationActionType type='Person' status={personTab} />
                        </div>
                    </NavigationActions>
                </RightContent>
            } */}
        </Content>
    );
  }

  export default UserViewTemplate