export const NutritionList = [
    {
        name : 'Vegan',
        status: false
    },
    {
        name : 'Vegetarian',
        status: false
    },
    {
        name : 'Gluten Free',
        status: false
    },
    {
        name : 'Nut Free',
        status: false
    },
    {
        name : 'Sugar Free',
        status: false
    }
]