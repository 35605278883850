/* eslint-disable */
import React from "react"
import styled from 'styled-components';
import {Link} from 'react-router-dom'

import {ContentTextSmallBold, ContentTextSmallRegular} from '../constants/styles'
import {linkStyle} from '../constants/cssstyles'
import { profileImgLarge, profileImgSmall } from "../constants/cssstyles";
import { MyColors } from "../constants/colors";

const ContentBlock = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    // margin-top: 0.6rem;
    // margin-bottom: 0.6rem;
    padding: 0.4rem;
    // border: 1px solid ${MyColors.colors.lightgrey};
    // border-radous: 0.2rem;
`

const DetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`

function ProfileDetails(props) {

    const size = props.size

    if(size === 'large') {
        return(
            <Link to={props.linkTo} style={linkStyle}>
                <ContentBlock>
                    <img src={props.userPhoto} style={profileImgLarge} />
                    <DetailsContainer>
                        <ContentTextSmallBold style={{color: MyColors.colors.black}}>{props.userName}</ContentTextSmallBold>
                        <ContentTextSmallBold style={{color: MyColors.colors.black}}>{props.userDescription}</ContentTextSmallBold>
                    </DetailsContainer>
                </ContentBlock>
            </Link>
        )
    }
    else if(size === 'small') {
        return(
            <Link to={props.linkTo} style={linkStyle}>
                <ContentBlock>
                    <img src={props.userPhoto} style={profileImgSmall} />
                    <ContentTextSmallRegular style={{color: MyColors.colors.black}}>{props.userName}</ContentTextSmallRegular>
                </ContentBlock>
            </Link>
        )
    }
}

export default ProfileDetails