import axios from 'axios';
import {config} from '../../constants/config';

  //API - get meals
  export const login = async (email, password) => {
    const data = {
        email,
        password,
    }
    try{
        const res = await axios({
            method: 'POST',
            withCredentials: true,
            url: `${config.url.production}api/v1/users/login`,
            data,
            headers: {
                // 'Access-Control-Allow-Origin': '*', 
                'Content-Type': 'application/json'
              },
        });

        if(res.data.status = 'success') {
            return res.data;
        }
    }
    catch(err) {
        console.log(err);
        const message = err.response.data.message;
        return(message);
    }
}

export const signupUser = async (name, email, password, passwordConfirm) => {
    const data = {
        name, 
        email, 
        password, 
        passwordConfirm
    }
    try{
        const res = await axios({
            method: 'POST',
            url: `${config.url.production}api/v1/users/signup`,
            data,
            withCredentials: true,
        });

        if(res.data.status = 'success') {
            return res.data;
        }
    }
    catch(err) {
        const message = err.message
        return {message};
    }
}

export const logout = async () => {
    try{
        const res = await axios({
            method: 'GET',
            url: `${config.url.production}api/v1/users/logout`,
            withCredentials: true,
        });

        if(res.data.status = 'success') {
            return res.data;
        }
    }
    catch(err) {
        const message = err.message;
        return {message};
    }
}

export const resetPassword = async (id, password, passwordConfirm) => {
    try{
        const data = {
            password,
            passwordConfirm
        }

        const res = await axios({
            method: 'PATCH',
            url: `${config.url.production}api/v1/users/resetPassword/${id}`,
            data,
            headers: {
                // 'Access-Control-Allow-Origin': '*', 
                'Content-Type': 'application/json'
              },
        });

        if(res.data.status = 'success') {
            return res.data;
        }
    }
    catch(err) {
        const message = err.message
        return {message};
    }
}

export const forgotPassword = async (email) => {
    try{
        const data = {
            email
        }
        const res = await axios({
            method: 'POST',
            url: `${config.url.production}api/v1/users/forgotPassword`,
            data,
            withCredentials: true,
            headers: {
                // 'Access-Control-Allow-Origin': '*', 
                'Content-Type': 'application/json'
              },
        });

        if(res.data.status = 'success') {
            return res.data;
        }
    }
    catch(err) {
        const message = err.message
        return {message};
    }
}

export const changePassword = async (passwordCurrent, password, passwordConfirm) => {
    try{
        const data = {
            passwordCurrent,
            password,
            passwordConfirm
        }
        const res = await axios({
            method: 'PATCH',
            url: `${config.url.production}api/v1/users/updateMyPassword`,
            data,
            withCredentials: true,
            headers: {
                // 'Access-Control-Allow-Origin': '*', 
                'Content-Type': 'application/json'
              },
        });

        if(res.data.status = 'success') {
            return res.data;
        }
    }
    catch(err) {
        const message = err.response.data.message;
        return(message);
    }
}

export const updateDetails = async (data) => {
    try{
        const res = await axios({
            method: 'PATCH',
            url: `${config.url.production}api/v1/users/updateMe`,
            data,
            withCredentials: true,
            headers: {
                // 'Access-Control-Allow-Origin': '*', 
                'Content-Type': 'application/x-www-form-urlencoded'
              },
        });

        if(res.data.status = 'success') {
            return res.data;
        }
    }
    catch(err) {
        const message = err.message
        return {message};
    }
}

export const getUser = async () => {
    const res = await axios({
        method: 'GET',
        url: `${config.url.production}api/v1/users/getMe`,
        withCredentials: true,
    });

    return res;
}

export const getAllUsers = async (page) => {
    const res = await axios({
        method: 'GET',
        url: `${config.url.production}api/v1/users?limit=10&page=${page}`,
        withCredentials: true,
    });

    return res.data;
}

export const checkUserStillExists = async () => {
    const res = await axios({
        method: 'GET',
        url: `${config.url.production}api/v1/users/checkLoggedInStatus`,
        withCredentials: true,
    });

    return res.data;
}