import React, {useState, useEffect, useContext, useRef, useCallback} from 'react'
import {useParams, useNavigate} from 'react-router-dom'
import styled from 'styled-components';

import { ContentTextMediumBold } from '../constants/styles';
import GeneralViewTemplate from '../components/screenTemplates/generalViewTemplate';
import { UserContext } from '../store/context/user-context';
import SearchFilterBar from '../components/searchFilterBar';
import { getAllUsers } from '../controllers/api/usersController';
import ChefCard from '../components/chefCard';
import useChefs from '../hooks/useChefs';
import {config} from '../constants/config'

const PageContent = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
`

const ContentWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
`

const Filters = styled.div`
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
`

function Chefs() {
    //Check if there is a user
    const navigate = useNavigate();
    const params = useParams();

    const observer = useRef();

    const [pageNum, setPageNum] = useState(1);

    const { 
        setPageName,
        mealsContent,
        setMealsContent,
        pageName
    } = useContext(UserContext);

    const {
        chefs,
        loading,
        error,
        hasMore
    } = useChefs(pageNum);

    const [searchTerm, setSearchTerm] = useState('');
    const [users, setUsers] = useState([]);
    const [usersToDisplay, setUsersToDisplay] = useState(users);

    const getUsers = async() => {
        const res = await getAllUsers();

        if(res.status === 'success') {
            setUsers(res.doc);
            setUsersToDisplay(res.doc);
        }

    }

    const lastMealRef = useCallback(meal => {
        if(loading) return;

        if(observer.current) observer.current.disconnect();

        observer.current = new IntersectionObserver(meals => {
            if(meals[0].isIntersecting && hasMore) {
                setPageNum(prev => prev+1);
            }
        })

        if(meal) observer.current.observe(meal);
    }, [loading, hasMore]);

    useEffect(() => {
        //get all the meals
        document.title = 'My Meals - Expert Recipes';
        setPageName('chefs');
        getUsers();
    },[]);

    useEffect(() => {
        const temp = []
        const usersToSearchFrom = users;
            
        if(searchTerm !== ''){
            for(let i = 0; i < usersToSearchFrom.length; i++) {
                if(usersToSearchFrom[i].name.toLocaleLowerCase().includes(searchTerm)) {
                    //check if it belongs to the selected section
                    temp.push(usersToSearchFrom[i]) 
                }
            setUsersToDisplay(temp);
            }
        }
        else {
            setUsersToDisplay(usersToSearchFrom)

        }
    },[searchTerm]);

    function navigateToChef(chef) {
        //redirect if not on the page already

        if(!params.slug){
            navigate(`/chefs/${chef._id}`, 
            {state: {
                screen: 'chefmeals',
                name: chef.name,
                description: chef.description,
                photo: chef.photo,
                ratingAverage: chef.ratingAverage,
            }});
        }
      }
      
    const contentToShow =
    <PageContent>
    {
        (users.length > 0) ?

        <Filters>
            <SearchFilterBar searchTerm={searchTerm} handleSearch={(val) => {setSearchTerm(val.target.value)}} />
        </Filters>
        :
        ''
    }
    <ContentWrapper>

        {
            (chefs.length > 0) ?
            chefs.map((item, index) => {
                return (
                    <ChefCard
                        ref={lastMealRef}
                        key={index}
                        name={item.name}
                        description={item.description}
                        numOfMeals ={item.ratingQuantity}
                        photo={item.photo}
                        ratingAverage={item.ratingAverage}
                        openDetails={()=>{
                            navigateToChef(item);
                        }}
                    />
                )
            })
                :
            <ContentTextMediumBold>No Chefs found.</ContentTextMediumBold>
        }
        </ContentWrapper>
        <div>{loading && 'Loading...'}</div>
        <div>{error && 'Error'}</div>
    </PageContent>

    return (
        <GeneralViewTemplate 
            pageContent = {contentToShow}
            selectedScreen = 'chefs'
        />
    );
  }

  export default Chefs