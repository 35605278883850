/* eslint-disable */
import React, {useContext, useState} from 'react'
import styled from 'styled-components';

import { MainHeading, ErrorText, Action} from '../constants/styles'
import MyInput from '../components/MyInput';
import { MyColors } from '../constants/colors';
import {UserContext} from '../store/context/user-context';
import {updateDetails, changePassword} from '../controllers/api/usersController'
import {logout} from '../controllers/api/usersController'
import UserViewTemplate from '../components/screenTemplates/userViewTemplate';
import MyButton from '../components/MyButton';
import { config } from '../constants/config';

const Wrapper = styled.div`
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: center;
    // position: relative;
`

const ErrorWrapper = styled.div`
    // position: absolute;
    // top: 0;
    width: 200px;
    word-wrap: break-word
`

const FormWrapper = styled.div`
    min-width: 20rem;    
    margin: 0.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    border-radius: 0.4rem;
    background-color: ${MyColors.colors.white};
`
const UserPhotoWrapper = styled.div`
    min-width: 20rem;    
    margin: 0.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    border-radius: 0.4rem;
    background-color: ${MyColors.colors.white};
`

function Profile() {
    const { user, setUser } = useContext(UserContext);
    const [errorMesg, setErrorMsg] = useState('');
    const [selectedPhoto, setSelectedPhoto] = useState();
    const [userPhoto, setUserPhoto] = useState(`${config.url.production}img/users/${user.photo}`)

    const updatePassword = async () => { 

        const passwordCurrent = document.getElementById('currentpassword').value;
        const password = document.getElementById('newpassword').value;
        const passwordConfirm = document.getElementById('confirmnewpassword').value;

        const res = await changePassword(passwordCurrent, password, passwordConfirm);

        if(res.status === 'success') {
            setUser(res.data.user)
            location.assign('/profile');
        }
        else {
            setErrorMsg(res)
        }
    }

    const changePhoto = async (event) => {
        setSelectedPhoto(event.target.files[0]);
        setUserPhoto(URL.createObjectURL(event.target.files[0]));
    }

    const updateDetailsHandler = async () => {
        const form = new FormData();
        form.append('name', document.getElementById('name').value);
        form.append('description', document.getElementById('description').value);
        form.append('photo', selectedPhoto);

        const res = await updateDetails(form, 'data')
        
        if(res.status === 'success') {
            setUser(res.data.user)
        }
    }

    const logoutUser = async() => {
        const res = await logout();

        if(res.status === 'success') {
            location.assign('/');
        }
        else {
            console.log(res.message);
        }
    }

    const contentToShow = 
    <>
        <UserPhotoWrapper>
            {/* <ButtonPrimary onClick={logoutUser}>Logout</ButtonPrimary> */}
            <MainHeading>Change Details</MainHeading>
            <img src={userPhoto} style={{width: 50, height: 50, borderWidth: 0.5, borderRadius: 200}} />
            <input type='file' accept='image/*' name='photo' id='photo' onChange={changePhoto} />

            <MyInput 
                label="Name" 
                inputValue= {user.name} 
                inputId='name'
            />

            <MyInput 
                label="About Me" 
                inputValue= {user.description} 
                inputId='description'
            />

            <MyButton type='primary' action={updateDetailsHandler} label='Update Details' />
        </UserPhotoWrapper>

        <FormWrapper>
            {
                Object.keys(user).length !== 0 ? 
                <Wrapper>
                    <ErrorWrapper>
                        {
                            errorMesg === '' ?
                            ''
                            :
                            <ErrorText>{errorMesg}</ErrorText>
                        }
                    </ErrorWrapper>

                    <div>
                        <MainHeading>Change Password</MainHeading>
                        <MyInput 
                            label="Current Password" 
                            inputValue="" 
                            inputId='currentpassword'
                        />
                        <MyInput 
                            label="New Password" 
                            inputValue="" 
                            inputId='newpassword'
                        />
                        <MyInput 
                            label="Confirm New Password" 
                            inputValue="" 
                            inputId='confirmnewpassword'
                        />
                        <MyButton type='primary' action={updatePassword} label='Change Password' />
                    </div>
                </Wrapper>
                :
                <Wrapper>
                    <p>{user.name}You are not logged in</p>
                    <Action href={'/'}>Back to home</Action>
                </Wrapper>
            }
        </FormWrapper>
    </>

    return (
        // <UserViewTemplate 
        //     pageContent = {contentToShow}
        //     selectedScreen = 'profile'
        // />

        <div>
            {contentToShow}
        </div>
    );
  }

  export default Profile