/* eslint-disable */
import React, {useContext, useState} from 'react'
import styled from 'styled-components';
import {useNavigate} from 'react-router-dom'

import {MainHeading, ErrorText, Action, ContentTextMediumRegular} from '../constants/styles'
import MyInput from '../components/MyInput';
import { MyColors } from '../constants/colors';
import {login, forgotPassword} from '../controllers/api/usersController'
import {UserContext} from '../store/context/user-context';
import MyButton from '../components/MyButton';

const FormWrapper = styled.div`
    min-width: 20rem;    
    margin: 0.5rem;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    border-radius: 0.5rem;
    background-color: ${MyColors.colors.lightestgrey};
`

const SectionContainer = styled.div`
    min-width: 20rem;    
    margin: 0.5rem;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    border-radius: 0.5rem;
    background-color: ${MyColors.colors.lightestgrey};
`

const ContentWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const ButtonWrapper = styled.div`
    display: flex;
    // flex: 1;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const PasswordContainer = styled.div`
    display: flex;
    // flex: 1;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
`

    function Login() {
        let navigate = useNavigate();

        const { user, setUser } = useContext(UserContext);
        const [errorMesg, setErrorMsg] = useState('')
        // const userExists = userContext.user? userContext.user: '';
        
        const loginUser = async () => { 
            const email = document.getElementById('email').value;
            const password = document.getElementById('password').value;

            const res = await login(email, password);
console.log(res)
            if(res.status === 'success') {
                const loggedInUser = res.data.user
                setUser(loggedInUser);
                navigate('/');
            }
            else {
                setErrorMsg(res)
            }
        }

        return (
        <ContentWrapper>
            {
                errorMesg === '' ?
                ''
                :
                <ErrorText>{errorMesg}</ErrorText>
            }

            {
                Object.keys(user).length === 0 ? 
                <>
                <FormWrapper>
                    <MainHeading>Login or Register</MainHeading>
                    <MyInput 
                        label="Email" 
                        inputValue="" 
                        inputId='email'
                    />

                    <PasswordContainer>
                        <MyInput 
                            type="password"
                            label="Password" 
                            inputValue="" 
                            inputId='password'
                        />   
                        <MyButton type='link' action={()=>{navigate('/forgotPassword')}} label='Forgot Password' />
                    </PasswordContainer>

                    <ButtonWrapper>
                        <MyButton type='primary' action={loginUser} label='Login' />
                    </ButtonWrapper>

                </FormWrapper>

                <SectionContainer>
                    <ContentTextMediumRegular>If you don't have an account, create one here.</ContentTextMediumRegular>
                    <ButtonWrapper>
                        <MyButton type='primary' action={()=>{navigate('/register')}} label='Register' />  
                    </ButtonWrapper>
                </SectionContainer>
                </>
                :
                <>
                    <p>{user.name} logged in</p>
                    <Action href={'/'}>Back to home</Action>
                </>    
            }
        </ContentWrapper>
    );
  }

  export default Login