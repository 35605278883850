import './App.css';
import {
  Routes, //replaces "Switch" used till v5
  Route,
  BrowserRouter
} from "react-router-dom";

import {UserProvider} from './store/context/user-context';

import AllMeals from './views/allmeals'
import MyMeals from './views/mymeals'
import Meal from './views/meal'
import Error from './views/errorscreens/errorscreen'
import Login from './views/login'
import Profile from './views/profile'
import ResetPassword from './views/resetPassword'
import Register from './views/register'
import ForgotPassword from './views/forgotPassword';
import ShoppingList from './views/shoppingList';
import MyCalendar from './views/myCalendar';
import Chefs from './views/chefs';
import ChefDetails from './views/chefDetails';
import UserProfileNav from './components/userProfileNav';

import {Content} from './constants/styles'
import AppHeader from './components/appHeader'
import AppFooter from './components/appFooter';

function App() {
  return (
    <UserProvider>
    <div className="App" >
      {/* <ThemeProvider theme={theme}> */}
          <BrowserRouter>
          
            <AppHeader login="/login" />
            <UserProfileNav />

            {/* <HeaderNav /> */}

            <Content> 

            <Routes>
              <Route path="/" element={<AllMeals />}>
                <Route index element={<AllMeals />} />
              </Route>
              <Route path="/:slug" element={<Meal />}>
              </Route>
              <Route path="/add/:section" element={<Meal />}>
              </Route>
              <Route path="/mymeals/:slug" element={<Meal />}>
              </Route>
              <Route path="/mymeals/add/:section" element={<Meal />}>
              </Route>
              <Route path="/login" element={<Login />}>
              </Route>
              <Route path='/forgotPassword' element={<ForgotPassword />}>
              </Route>
              <Route path="/profile" element={<Profile />}>
              </Route>
              <Route path="/resetPassword/:id" element={<ResetPassword />}>
              </Route>
              <Route path="/register" element={<Register />}>
              </Route>
              <Route path="/mymeals" element={<MyMeals />}>
              </Route>
              <Route path="/calendar" element={<MyCalendar />}>
              </Route>
              <Route path="/shoppinglist" element={<ShoppingList />}>
              </Route>
              
              <Route path="/chefs" element={<Chefs />}>
              </Route>
              <Route path="/chefs/:id" element={<ChefDetails />}>
              </Route>

              {/* ERROR SCREENS */}
              <Route path="/error" element={<Error />}>
              </Route>
              <Route path="*" element={<Error />}>
              </Route>
            </Routes>

            </Content>

            <AppFooter />
          </BrowserRouter>
      {/* </ThemeProvider> */}
    </div>
    </UserProvider>
  );
}

export default App;

{/* <a
  className="App-link"
  href="https://reactjs.org"
  target="_blank"
  rel="noopener noreferrer"
>
  Learn React
</a> */}
