import React from "react"
import styled from 'styled-components';
import Modal from 'react-modal';

import { MyColors } from "../constants/colors";
import {ContentTextMediumBold, ContentTextMediumRegular} from '../constants/styles';
import MyButton from "./MyButton";

const ModalWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: ${MyColors.colors.white};
    border: 1px solid ${MyColors.colors.lightgrey};
    z-index: 1;
    width: 50%;
    border-radius: 0.5rem;
    position: absolute;
    top: 10%;
`

const Header = styled.div`
    display: flex;
    padding: 0.5rem;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    background-color: ${MyColors.colors.lightestgrey};
`

const Footer = styled.div`
    display: flex;
    padding: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    background-color: ${MyColors.colors.lightestgrey};
`

const ModalBackground = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(27, 27, 27, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
`

const ModalContent = styled.div`
    display: flex;
    flex:1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    min-height: 10px;
    max-height: 400px;
    padding: 0.8rem;

    scroll-behaviour: smooth;
    overflow-y: scroll;
    overflow-x: hidden;
    white-space: nowrap;
    background-color: ${MyColors.colors.white};
`

const ErrorMessage = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
`

function MyModal(props) {
    return (
        props.modalState?
        <ModalBackground>
            <ModalWrapper>

                <Header>
                    <ContentTextMediumBold>{props.modalTitle}</ContentTextMediumBold>
                </Header>

                <ModalContent>
                    {
                        props.error?
                        <ErrorMessage>
                            <ContentTextMediumRegular style={{color:MyColors.colors.red}}>{props.error}</ContentTextMediumRegular>
                        </ErrorMessage>
                    :
                        ''
                    }
                    
                    {props.modalContent}
                </ModalContent>

                <Footer>
                    {
                        props.dualButtons?
                        <>
                            <MyButton type='secondary' action={props.closeAction} label='close' />
                            <MyButton type='primary' action={props.modalAction} label={props.modalActionTitle} />
                        </>
                        :
                        <MyButton type='primary' action={props.closeAction} label='close' />
                    }
                </Footer>

            </ModalWrapper>  
        </ModalBackground>
        :
        ''  
    )
}

export default MyModal