import { createContext, useEffect, useState } from "react";
import {getTodaysDate} from '../../components/dateTimeComponent'
import { getAllMeals, getScheduledMeals, addMealToSchedule, getScheduledMeal, removeMealFromSchedule } from "../../controllers/api/mealsController";
import {addReview} from '../../controllers/api/reviewController'
import {addToShoppingList, removeFromShoppingList, saveShoppingList} from '../../controllers/api/shoppingListController'

const userDetailsState = {};
const feedbackMessageState = ''
const ErrorMessageState = ''

//Calendar
const getDate = getTodaysDate();
const selectedCalendarDay = getDate;
const allDatesWithMeals = [];
const allScheduledMeals = [];
const selectedDayBreakfastMeals = [];
const selectedDayLunchMeals = [];
const selectedDayDinnerMeals = [];

//Meals
const allMealsList = [];
const myMealsList = [];
const allLoadedMeals = [];

//Sections
const mySectionsList = [];

//Reviews
const allMealReviews = [];

//Shopping List
const myShoppingList = [];

export const UserContext = createContext();

export const UserProvider = (props) => {

    const [user, setUser] = useState(userDetailsState);
    const [feedbackMessage, setFeedbackMessage] = useState(feedbackMessageState);
    const [errorMessage, setErrorMessage] = useState(ErrorMessageState);
    const [pageName, setPageName] = useState('home');

    useEffect(() => {
        setTimeout(() => {
            setFeedbackMessage('');
        }, 3000);
    },[feedbackMessage]);

    //Calendar
    const [selectedCalDay, setSelectedCalDay] = useState(selectedCalendarDay);
    const [datesWithMeals, setDatesWithMeals] = useState(allDatesWithMeals);
    const [scheduledMeals, setScheduledMeals] = useState(allScheduledMeals);
    const [selectedCalDayBreakfastMeals, setSelectedCalDayBreakfastMeals] = useState(selectedDayBreakfastMeals);
    const [selectedCalDayLunchMeals, setSelectedCalDayLunchMeals] = useState(selectedDayLunchMeals);
    const [selectedCalDayDinnerMeals, setSelectedCalDayDinnerMeals] = useState(selectedDayDinnerMeals);
    
    ///CALENDAR FUNCTIONS////////////////////////////////////////////////////////////////////////
    const PopulateScheduledMeals = async() => {
        try{
            const res = await getScheduledMeals(selectedCalDay.Tdate, user._id);

            if(res.data.status === 'success') {
                const meals = res.data.doc;

                setDatesWithMeals(res.data.mealDates)

                setScheduledMeals(meals);
                // setSelectedCalDayBreakfastMeals(meals.filter(item => item.mealType == 'Breakfast' && item.scheduleDate == selectedCalDay.Tdate));
                // setSelectedCalDayLunchMeals(meals.filter(item => item.mealType == 'Lunch' && item.scheduleDate == selectedCalDay.Tdate));
                // setSelectedCalDayDinnerMeals(meals.filter(item => item.mealType == 'Dinner' && item.scheduleDate == selectedCalDay.Tdate));
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    const addMealToPlanner = async(data) => {
        try {
            const mealExists = await getScheduledMeal(data);

            if(mealExists && mealExists.data.length === 0) {
                const res = await addMealToSchedule(data);

                if(res.data.status === 'success') {
                    //add to context
                    const temp = [...scheduledMeals];
                    temp.push(res.data.doc);
                    setScheduledMeals(temp);

                    //set feedback
                    setFeedbackMessage('Meal added to calendar')
                }
            }
            else {
                console.log('meal exisits')
            }
        }
        catch(err){
            console.log(err);
            // navigate('/error', {
            //     state: {
            //         name: err.name,
            //         message: err.message,
            //         code: err.code,
            //         statuscode: err.response.status
            //     }
            // });
        }
    }

   const removeMealFromPlanner = async(scheduleId) => {
        try {
            const res = await removeMealFromSchedule(scheduleId);

            if(res.data.status === 'success') {
                const temp = [...scheduledMeals];
                const index = temp.findIndex((item) => item.id === scheduleId)
                temp.splice(index, 1);
                setScheduledMeals(temp);

                setFeedbackMessage('Meal removed from planner');
            }
        }
        catch(err) {
            console.log(err);
            // navigate('/error', {
            //     state: {
            //         name: err.name,
            //         message: err.message,
            //         code: err.code,
            //         statuscode: err.response.status
            //     }
            // });
        }
   }

   const hideUnpublishedMeal = (mealId, publishStatus) => {

        const temp = [...scheduledMeals];

        for(let i=0; i < temp.length; i++) {
            if(temp[i].mealId.id === mealId) {
                temp[i].mealId.published = publishStatus;
            }
        }
        setScheduledMeals(temp);
   }

    ///REVIEW FUNCTIONS////////////////////////////////////////////////////////////////////////

    //Reviews
    const [mealReviews, setMealReviews] = useState(allMealReviews);

    const addMealReview = async(data, selectedMealId) => {
        try{
            const res = await addReview(data, selectedMealId);

            if(res.status === 'success' && res.action === 'update') {

                //add to context
                const temp = [...mealReviews];
                const index = temp.findIndex((item) => item.id === res.data.id);

                temp[index].reviewDescription = res.data.reviewDescription;
                temp[index].rating = res.data.rating;
                setMealReviews(temp);
            }
            else if(res.status === 'success' && res.action === 'add') {
                const temp = [...mealReviews];
                temp.push(res.data);
                setMealReviews(temp);
            }
        }
        catch (err) {
            console.log(err);
            // navigate('/error', {
            //     state: {
            //         name: err.name,
            //         message: err.message,
            //         code: err.code,
            //         statuscode: err.response.status
            //     }
            // });
        }
    } 

    ///MEAL FUNCTIONS////////////////////////////////////////////////////////////////////////

    //Meals
    const [mealsContent, setMealsContent] = useState(allMealsList);
    const [myMeals, setMyMeals] = useState(myMealsList);
    const [noMore, setNoMore] = useState(true);
    const [page, setPage] = useState(1);

    const populateAllMeals = async() => {
        try {
            const meals = await getAllMeals(page);
            return meals;
        }
        catch (err) {
            console.log(err);
            // navigate('/error', {
            //     state: {
            //         name: err.name,
            //         message: err.message,
            //         code: err.code,
            //         statuscode: err.response.status
            //     }
            // });
        }
    }

    //Sections
    const [mySections, setMySections] = useState(mySectionsList);

    function updateMySection(sectionToUpdate) {
        mySections.push(sectionToUpdate)
    }

    function deleteMySection(sectionToDelete) {
        const temp = [...mySections];
        var index = temp.findIndex((section) => section.sectionId == sectionToDelete.id);

        if (index !== -1) {
            temp.splice(index, 1);
            setMySections(temp);
          }
    }

    function changeSectionStatus(type, status, index) {
        const temp = [...mySections];
        switch(type) {
            case 'remove':
                temp[index].sectionRemoveStatus = status;
        }
        setMySections(temp);
    }

    ///SHOPPING LIST FUNCTIONS////////////////////////////////////////////////////////////////////////
    const [shoppingList, setShoppingList] = useState(myShoppingList);

    const addToList = async(data) => {
        try {
            const res = await addToShoppingList(data);

            if(res.status === 'success') {
                const temp = [...shoppingList];
                temp.push(data);
                setShoppingList(temp);

                setFeedbackMessage('Item added successfully');
            }
        }
        catch(err) {
            console.log(err);
            // navigate('/error', {
            //     state: {
            //         name: err.name,
            //         message: err.message,
            //         code: err.code,
            //         statuscode: err.response.status
            //     }
            // });
        }
    }

    const removeFromList = async(id) => {
        try {
            const res = await removeFromShoppingList(id);

            if(res.data.status === 'success') {
                const temp = [...shoppingList];
                const index = temp.findIndex((item) => item._id === id);
                temp.splice(index, 1);
                setShoppingList(temp);

                setFeedbackMessage('Item removed successfully');
            }
        }
        catch(err) {
            console.log(err);
            // navigate('/error', {
            //     state: {
            //         name: err.name,
            //         message: err.message,
            //         code: err.code,
            //         statuscode: err.response.status
            //     }
            // });
        }
    }

    const saveListName = async(id, newName) => {
        const data = {
            itemName: newName
        }

        try {
            const res = await saveShoppingList(id, data);

            if(res.data.status === 'success') {
                const temp = [...shoppingList];
                const index = temp.findIndex((item) => item._id === id);
                temp[index].itemName = newName;
                setShoppingList(temp);

                setFeedbackMessage('Item removed successfully');
            }
        }
        catch(err) {
            console.log(err);
            // navigate('/error', {
            //     state: {
            //         name: err.name,
            //         message: err.message,
            //         code: err.code,
            //         statuscode: err.response.status
            //     }
            // });
        }
    }

    const tickList = async(id, ticked) => {
        const data = {
            ticked
        }

        try {
            const res = await saveShoppingList(id, data);

            if(res.status === 'success') {
                const temp = [...shoppingList];
                const index = temp.findIndex((item) => item.id === id);

                temp[index].ticked = data.ticked;
                setShoppingList(temp);
            }
        }
        catch(err) {
            console.log(err);
            // navigate('/error', {
            //     state: {
            //         name: err.name,
            //         message: err.message,
            //         code: err.code,
            //         statuscode: err.response.status
            //     }
            // });
        }
    }

    const reorderList = async(data) => {

        try {
            const res = await saveShoppingList(data);

            if(res.status === 'success') {
                setShoppingList(res.dic);
            }
        }
        catch(err) {
            console.log(err);
        }
    }

    ///PROFILE TAB FUNCTIONS////////////////////////////////////////////////////////////////////////
    const [calendarTab, setCalendarTab] = useState(false);
    const [personTab, setPersonTab] = useState(false);
    const [listTab, setListTab] = useState(false);

    const [selectedTab, setSelectedTab] = useState('');


    return <UserContext.Provider
    value={{
        user,
        setUser,
        feedbackMessage,
        setFeedbackMessage,
        pageName,
        setPageName,

        selectedCalDay,
        setSelectedCalDay,
        datesWithMeals,
        scheduledMeals,
        setScheduledMeals,
        selectedCalDayBreakfastMeals,
        setSelectedCalDayBreakfastMeals,
        selectedCalDayLunchMeals,
        setSelectedCalDayLunchMeals,
        selectedCalDayDinnerMeals,
        setSelectedCalDayDinnerMeals,
        PopulateScheduledMeals: PopulateScheduledMeals,
        addMealToPlanner: addMealToPlanner,
        removeMealFromPlanner: removeMealFromPlanner,
        hideUnpublishedMeal: hideUnpublishedMeal,

        mealsContent,
        setMealsContent,
        myMeals,
        setMyMeals,
        populateAllMeals: populateAllMeals,
        noMore,
        setNoMore,
        page,
        setPage,

        mealReviews,
        setMealReviews,
        addMealReview: addMealReview,

        mySections,
        setMySections,
        updateMySection: updateMySection,
        deleteMySection: deleteMySection,
        changeSectionStatus: changeSectionStatus,

        shoppingList,
        setShoppingList,
        removeFromList: removeFromList,
        addToList: addToList,
        saveListName: saveListName,
        tickList: tickList,

        calendarTab,
        setCalendarTab,
        personTab,
        setPersonTab,
        listTab,
        setListTab,
        selectedTab,
        setSelectedTab
    }}>
        {props.children}
    </UserContext.Provider>
}