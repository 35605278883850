import React from "react"
import styled from 'styled-components';
import { Done } from '@mui/icons-material';

import { iconWhite } from '../../constants/cssstyles';
import { MyColors } from "../../constants/colors";

const BoxDefault = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 1.3rem;
    height: 1.3rem;
    border-radius: 0.2rem;
    border: solid 1px ${MyColors.colors.primary};
`

const BoxChecked = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 1.3rem;
    height: 1.3rem;
    border-radius: 0.2rem;
    background-color: ${MyColors.colors.primary};
`

function MyCheckbox(props) {

    return (
        props.checked?
                <BoxChecked onClick={props.action}>
                     <Done sx={iconWhite} />
                </BoxChecked>
            :
                <BoxDefault onClick={props.action} />
    )
}

export default MyCheckbox