import React from "react"
import styled from 'styled-components';
import {ContentTextMediumRegular} from '../constants/styles'
import { MyColors } from "../constants/colors";

const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 0.4rem;
`

const Input = styled.input`
    min-width: 4rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    padding: 0.8rem;
    margin: 0;
    border-radius: 0.2rem;
    border: 1px solid ${MyColors.colors.lightgrey};
    box-shadow:none;
    color: ${MyColors.colors.black};
    font-size: 16px;
    background-color: ${MyColors.colors.white};
`

const inputWidth = {
    small: 100,
    large: 200
}

function MyInput(props) {
    const showLabel = props.showLabel;
    const widthToSet = props.width === 'small'? inputWidth.small : inputWidth.large;

    if(showLabel === false) {
        return (
            <InputWrapper>
                <Input
                    style={{width: widthToSet}}
                    defaultValue={props.inputValue}
                    id={props.inputId}
                    onChange={props.handleChange} 
                    placeholder={props.placeholder}
                />
            </InputWrapper>
        )
    }
    else {
        return (
            <InputWrapper>
                <ContentTextMediumRegular style={{color: MyColors.colors.darkgrey}}>{props.label}</ContentTextMediumRegular>
                <Input 
                    style={{width: widthToSet}}
                    defaultValue={props.inputValue}
                    id={props.inputId}
                    onChange={props.handleChange} 
                    placeholder={props.placeholder}
                />
            </InputWrapper>
        )
    }
}

export default MyInput