import React, {useState, useEffect, useContext} from 'react'
import styled from 'styled-components';

import {getMyShoppingList} from '../controllers/api/shoppingListController'
import {UserContext} from '../store/context/user-context';
import UserViewTemplate from '../components/screenTemplates/userViewTemplate';
import MyInput from '../components/MyInput';
import { ContentTextMediumRegular } from '../constants/styles';
import MyList from '../components/MyList';
import {listEditHandler, moveDownInList, moveUpInList} from '../controllers/frontend/listController';
import MyButton from '../components/MyButton';
import MyModal from '../components/MyModal';

const ContentWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`

const AddItemBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`

function ShoppingList() {
    //Check if there is a user
    const { 
        user, 
        shoppingList, 
        setShoppingList, 
        addToList,
        removeFromList,
        saveListName,
        tickList
    } = useContext(UserContext);

    const [listItemName, setListItemName] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

    const getMyList = async() => {
        try {
            const res = await getMyShoppingList(user._id)
            if(res.status === 'success') {
                setShoppingList(res.doc);
            }
        }
        catch (err) {
            setErrorMessage(err.data.message);
        }
    }

    useEffect(() => {
        getMyList();
    },[]);

    const addItemToList = () => {
        // const temp = [...shoppingList];

        const item = {
            user: user._id,
            itemName: listItemName,
            ticked: false,
            editStatus: false,
            removeStatus: false,
        }

        // temp.push(item);
        addToList(item);

       // setShoppingList(temp);
    }

    const saveItemName = (id, itemIndex) => {
        const temp = [...shoppingList]
        temp[itemIndex].editStatus = false;
        setShoppingList(temp);

        saveListName(id, shoppingList[itemIndex].itemName);
    }
        
    const ChangeItemText = (itemIndex, text) => {
        const tempCardArray = []
        for(let i = 0; i < shoppingList.length; i++) {
            tempCardArray.push(shoppingList[i])
        }
        tempCardArray[itemIndex].itemName = text
        setShoppingList(tempCardArray)
    }

    function closeModal(modalName) {
        document.body.style.overflow = "auto";
        
        if(modalName === 'deletemeal') {
            setDeleteModalIsOpen(false);
        }
    }

      function openModal(modalName) {
        document.body.style.overflow = "hidden";

        if(modalName === 'deletemeal') {
            setDeleteModalIsOpen(true);
        }
    }

    const removeMealHandler = (id) => {
        removeFromList(id);
    }

    const deleteMealModalContent =
        <>
            <ContentTextMediumRegular>Are you sure you want to delete this item from the shopping list?</ContentTextMediumRegular>
        </>

    const contentToShow = 
    <ContentWrapper>

        {/* MODAL - DELETE MEAL */}
        <MyModal 
            modalState={deleteModalIsOpen}
            closeAction={() => {closeModal('deletemeal')}}
            dualButtons={true}
            modalTitle='Delete item From Shopping list'
            modalContent={deleteMealModalContent}
            modalActionTitle='Delete'
            modalAction={() => {removeMealHandler()}}
        />

        <AddItemBox>
            <MyInput 
                showLabel={false}
                inputValue={listItemName}
                inputId='itemname'
                placeholder='Add item name here...'
                handleChange={(val) => {setListItemName(val.target.value)}}
            />

            <MyButton type='primary' action={addItemToList} label='Add' />

        </AddItemBox>

        <>
            {
                shoppingList.map((item, index) => {
                    return (
                        <MyList 
                            key={index}
                            listName={item.itemName} 
                            listId = {index} 
                            editStatus = {item.editStatus}
                            active = {true}
                            removeStatus = {item.removeStatus}
                            confirmRemove = {() => {
                                removeMealHandler(item.id);
                            }}
                            undo = {()=>{
                                const temp = [...shoppingList];
                                temp[index].removeStatus = false;
                                setShoppingList(temp);
                            }}
                            toggleListEdit = {()=>{
                                const newList = listEditHandler(shoppingList, index);
                                setShoppingList(newList);
                            }}
                            saveListName = {()=>{
                                saveItemName(item.id, index);
                            }}

                            removeList = {()=>{
                                // openModal('deletemeal')
                                const temp = [...shoppingList];
                                temp[index].removeStatus = true;
                                setShoppingList(temp);
                            }}

                            handleListEdit={(val) => {ChangeItemText(index, val.target.value)}}
                            numberOfItems = '0'
                            sortActions={true}
                            moveUp={()=>{
                                const newList = moveUpInList(shoppingList, index);
                                if(newList) {
                                    setShoppingList(newList);
                                }
                            }}
                            moveDown={()=>{
                                const newList = moveDownInList(shoppingList, index);
                                if(newList) {
                                    setShoppingList(newList);
                                }
                            }}
                            checkbox={true}
                            checked={item.ticked}
                            action={() => {
                                const tick = item.ticked? false : true;
                                tickList(item.id, tick);
                            }}
                        />
                    )
                })
        }
        </>
    </ContentWrapper>

    return (
        // <UserViewTemplate 
        //     pageContent = {contentToShow}
        //     selectedScreen = 'shoppinglist'
        // />
        <>
            {contentToShow}
        </>
    );
  }

  export default ShoppingList