import { BorderClear, BorderColor } from "@mui/icons-material"
import { MyColors } from "./colors"

export const linkStyle = {
    textDecoration: 'none'
}

export const NavLinkStyle = {
    textDecoration: 'none',
    textAlign: 'left',
    marginHorizontal: 4,
    marginVertical: 0,
    fontSize: 16,
    fontWeight: 600,
    color: MyColors.colors.black
}

export const profileImgSmall = {
    width: 30, 
    height: 30, 
    borderRadius: 200
}

export const profileImgLarge = {
    width: 50, 
    height: 50, 
    borderRadius: 200,
    objectFit: 'cover'
}

export const iconBlue = {
    fontSize: 24, 
    color: MyColors.colors.blue
}

export const iconPrimary = {
    fontSize: 24, 
    color: MyColors.colors.primary
}

export const iconSmall = {
    fontSize: 16, 
    color: MyColors.colors.blue
}

export const iconSmallStar = {
    fontSize: 16, 
    color: MyColors.colors.yellow,
}

export const iconDisabled = {
    fontSize: 24, 
    color: MyColors.colors.mediumgrey
}

export const iconWhite = {
    fontSize: 24, 
    color: MyColors.colors.white
}