import React from "react"
import {Link} from 'react-router-dom'

import {MyColors} from '../constants/colors'
import styled from 'styled-components';
import {linkStyle} from '../constants/cssstyles'
import {ContentTextMediumRegular, ContentTextLargeBold} from '../constants/styles'
import MyButton from "./MyButton";

const MealCardWrapper = styled.div`
    display: flex;
    flex:1;
    flex-direction: row;
    justify-content: 'flex-start';
    align-items: 'flex-start';
    width: 100%;
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
    border: 1px solid ${MyColors.colors.lightgrey};
    border-radius: 0.3rem;
    background-color: ${MyColors.colors.lightestgrey};
`

const MealDetailsContainer = styled.div`
    display: flex; 
    flex:1;
    flex-direction: column;  
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0.4rem;
`
const MealActions = styled.div`
    display: flex; 
    flex-direction: row;  
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
    margin-left: 0.4rem;
    margin-right: 0.4rem;
`

function ScheduledMealCard(props) {

    const published = props.published;

    if(published) {
        return (
            <MealCardWrapper style={{backgroundColor: props.background}}>
                <img src={`${config.url.production}img/meals/${props.mealImg}`} style={{width: 80, backgroundColor:'pink', objectFit: 'cover'}} />
                <MealDetailsContainer> 
                    <ContentTextLargeBold style={{color: MyColors.colors.black}}>{props.title}</ContentTextLargeBold>
                    <MealActions>
                        <MyButton type='primary' action={props.openMeal} label='Open Meal' />
                        <MyButton type='primary' action={props.removeMeal} label='Remove Meal' />
                    </MealActions>
                </MealDetailsContainer>
            </MealCardWrapper>
        )
    }

    return (
        <MealCardWrapper style={{backgroundColor: props.background}}>
        <img src={`${config.url.production}img/meals/default.jpg`} style={{width: 80, backgroundColor:'pink', objectFit: 'cover'}} />
        <MealDetailsContainer> 
            <ContentTextLargeBold style={{color: MyColors.colors.black}}>The user has removed this meal.</ContentTextLargeBold>
            <MealActions>
                {/* <ButtonPrimary onClick={props.openMeal}>Open Meal</ButtonPrimary> */}
                <MyButton type='primary' action={props.removeMeal} label='Remove Meal' />
            </MealActions>
        </MealDetailsContainer>
    </MealCardWrapper>
    )
}

export default ScheduledMealCard